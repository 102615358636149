import React from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  Typography,
} from "@mui/material"
import styled from "@emotion/styled"
import Header from "../../components/core/header"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const HelpCenter = () => {
  const steps = [
    {
      q: "What is HelloFixy?",
      a: "HelloFixy serves as an innovative online platform that seamlessly connects homeowners with skilled service professionals, catering to a diverse range of home improvement needs.",
    },
    {
      q: "How can I utilize HelloFixy to find a service professional?",
      a: `To engage a service professional through HelloFixy, simply post your project, detailing your requirements. Whether it's for yourself or your family, we offer a complimentary initial in-person or virtual consultation based on your project needs. Following this, you'll receive a free estimate, and if you're satisfied with the quote, you can proceed with the work.`,
    },
    {
      q: "Is HelloFixy exclusive to Chennai?",
      a: `Currently, HelloFixy's services are available in Chennai, with plans for expansion in the near future.`,
    },
    {
      q: "How do I make payments for services on HelloFixy?",
      a: "Securely handle payments on HelloFixy through our platform. After a professional completes your project, you can make online payments using our secure system, which includes options such as GPay and PhonePe.",
    },
    {
      q: "What types of home improvement services are offered on HelloFixy?",
      a: `HelloFixy encompasses a broad spectrum of home improvement services, ranging from plumbing, electrical work, carpentry, painting, landscaping, and more. Whether it's a minor repair or a major renovation, you'll find the right professional for the job.`,
    },
    {
      q: "How can I trust the service professionals on HelloFixy?",
      a: `Rest assured, all service professionals on HelloFixy undergo meticulous vetting and verification to meet our rigorous standards of quality and professionalism. Additionally, users will confirm an OTP before any professional enters the premises, ensuring an extra layer of security.`,
    },
    {
      q: "Can I request multiple quotes for a project on HelloFixy?",
      a: `Certainly! When posting a project, you have the flexibility to submit multiple work requests, allowing you to explore various options and choose the best fit for your needs.`,
    },
    {
      q: "What should I do if I encounter issues with a service professional?",
      a: "If any issues arise with a service professional, please reach out to our dedicated customer support team promptly. We take user feedback seriously and are committed to addressing and resolving any concerns you may have.",
    },
    {
      q: "Is there a fee for using HelloFixy?",
      a: `HelloFixy is entirely free for homeowners. There are no subscription fees or hidden charges; you only pay for the services you book through the platform once the work is successfully completed.`,
    },
  ]

  const FAQAccordion = styled(Accordion)`
    border: 1px solid #C1D0E0;
    box-shadow: none;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  `

  const isAuthenticated = localStorage.getItem("isAuthenticated")

  return (
    <Box sx={{ px: isAuthenticated ? 0 : 3, pb: 2, width: "100%" }}>
      <Header>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "5px 0px",
            }}>
            <Typography
              component='div'
              sx={{ fontSize: "32px", color: "#000000" }}>
              Help Center
            </Typography>
            <Typography
              component='div'
              sx={{ fontSize: "16px", color: "#A7A7A7" }}>
              Help centre is here to your support
            </Typography>
          </Box>
        </Box>
      </Header>

      <Card
        sx={{
          mt: 3,
          p: 2,
          width: "100%",
          boxShadow:
            "0px -10px 30px rgba(0, 0, 0, 0.2), 0px 10px 15px rgba(0, 0, 0, 0.1)",
        }}>
        <Typography
          component='h6'
          sx={{ fontSize: "14px", color: "#818B7D", px: 1, mt: 1 }}>
          Please check in you have similar queries before raising concern
        </Typography>
        <Typography
          component='div'
          sx={{
            mt: 2,
            fontSize: "16px",
            color: "#000000",
            fontWeight: 600,
            px: 1,
          }}>
          FAQ: HelloFixy - Your Home Improvement Solution
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
            width: "100%",
          }}>
          {steps.map((faq) => {
            return (
              <Grid xs={12} md={6} sx={{ px: 1, py: 2 }}>
                <FAQAccordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: "var(--primary)",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    }
                    aria-controls='panel1a-content'
                    id='panel1a-header'>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#36423c",
                        fontWeight: 400,
                      }}>
                      {" "}
                      {faq.q}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq.a}</Typography>
                  </AccordionDetails>
                </FAQAccordion>
              </Grid>
            )
          })}
        </Grid>
      </Card>
      {/* <Card sx={{ p: 2, mt: 3, width: '100%' }}>
        <Typography component="h6" sx={{ fontSize: '14px', color: '#818B7D', px: 1, mt: 1 }}>
          In case of any queries or concern
        </Typography>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, width: '100%' }}>
          <Grid xs={12} md={6} sx={{ px: 1 }}>
            <Grid item xs={12} sx={{ padding: ' 10px 0' }}>
              <Typography component="h6" sx={{ fontSize: '16px', color: '#1F261F' }}>
                Raise concern
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ padding: ' 10px 0' }}>
              <InputLabel htmlFor="category_name"> Project</InputLabel>
              <Select
                id="category_name"
                // value={selectedServiceEditDetails.category_name}
                // onChange={handleChange('category_name')}
                // onChange={(e) => handleQuestionChange(questionIndex, 'type', e.target.value)}
                required
                sx={{ width: '100%', mt: 1 }}
              >
                <MenuItem value="Landscaping">Landscaping</MenuItem>
                <MenuItem value="Building">Building</MenuItem>
                <MenuItem value="Building">Interior</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sx={{ padding: ' 10px 0' }}>
              <InputLabel htmlFor="category_name"> Type in your concern</InputLabel>
              <TextField
                id="notes"
                multiline
                fullWidth
                rows={4}
                margin="normal"
                // sx={{ width: '90%' }}
                //   value={notes}
                //   onChange={(event) => setNotes(event.target.value)}
                //   inputRef={notesInputRef}
              />
            </Grid>
            <Grid item xs={12} sx={{ padding: ' 10px 0', textAlign: 'right' }}>
              <SubmitButton
                variant="contained"
                color="primary"
                onClick={() => {
                  //   navigate('/login');
                }}
                sx={{ padding: '10px 30px' }}
              >
                Submit
              </SubmitButton>
            </Grid>
          </Grid>
          <Grid xs={12} md={2} sx={{ px: 1, py: 2, textAlign: 'center' }}>
            OR
          </Grid>
          <Grid xs={12} md={4} sx={{ px: 1, py: 2 }}>
            <Typography component="h6" sx={{ fontSize: '16px', color: '#1F261F', fontWeight: 600 }}>
              Please contact us
            </Typography>
            <Typography component="h6" sx={{ fontSize: '14px', color: '#1F261F', fontWeight: 400, my: 3 }}>
              +91 - 7777111188
            </Typography>
            <Typography component="h6" sx={{ fontSize: '14px', color: '#1F261F', fontWeight: 400, my: 3 }}>
              veetuworkshelp@gmail.com
            </Typography>
          </Grid>
        </Grid>
      </Card> */}
    </Box>
  )
}

export default HelpCenter
