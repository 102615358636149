import React from "react"
import Box from "@mui/material/Box"
import StarRoundedIcon from "@mui/icons-material/StarRounded"
import { styled } from "@mui/material/styles"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import Tooltip from "@mui/material/Tooltip"
import RatingMui from "@mui/material/Rating"

const StarRating = ({
  rating,
  progressBarColor = "#FFD700",
  maxRating = 5,
}: {
  rating: number
  starColor?: string
  progressBarColor?: string
  size?: { xs: string; md: string }
  maxRating?: number
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        padding: "8px 0",
        gap: { xs: "3px", md: "5px" },
      }}>
      <Box sx={{ fontWeight: "bold", fontSize: { xs: "14px", md: "16px" } }}>
        {rating.toFixed(1)}
      </Box>

      <Tooltip title={`${rating} out of ${maxRating}`} arrow>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RatingMui
            sx={{
              marginLeft: { xs: "3px", md: "5px" },
              "& .MuiRating-iconFilled": {
                color: progressBarColor,
              },
            }}
            name='half-rating-read'
            value={rating}
            precision={0.5}
            readOnly
            icon={
              <StarRoundedIcon sx={{ fontSize: { xs: "18px", md: "25px" } }} />
            }
            emptyIcon={
              <StarRoundedIcon sx={{ fontSize: { xs: "18px", md: "25px" } }} />
            }
          />
        </Box>
      </Tooltip>
    </Box>
  )
}

export default StarRating
