import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Tab,
  Tabs,
  TextareaAutosize,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

import { theme } from '../../utils/theme';
const ServiceCardEdit = (props: any) => {
  const { selectedServiceEditDetails, setEditService } = props;

  const StyledCard = styled(Card)`
    width: 100%;
    display: flex;
    padding: 20px;
    align-item: flex-start;
  `;

  const CardContentWrapperInner = styled(CardContent)`
    display: flex;

    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;

    width: 100%;
    padding: 0;
  `;

  const InputField = styled(TextField)`
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 16px;
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: ${theme.palette.primary.contrastText};
      }

      &:hover fieldset {
        border-color: ${theme.palette.primary.contrastText};
      }

      &.Mui-focused fieldset {
        border-color: ${theme.palette.primary.main};
      }

      &.Mui-error fieldset {
        border-color: #your-error-color; /* Error state */
      }
    }
  `;

  const Textarea = styled(TextareaAutosize)`
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 16px;
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: ${theme.palette.primary.contrastText};
      }

      &:hover fieldset {
        border-color: ${theme.palette.primary.contrastText};
      }

      &.Mui-focused fieldset {
        border-color: ${theme.palette.primary.main};
      }

      &.Mui-error fieldset {
        border-color: #your-error-color; /* Error state */
      }
    }
  `;
  const LoginButton = styled(Button)`
    text-align: left;
    color: var(--btn-text);
    background: var(--primary);
    font: Kufam;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px 20px;
    &:hover {
      background: var(--hover); /* Change the background color on hover */
    }

    &:active {
      background: var(--primary); /* Change the background color on click */
    }
  `

  const ErrorLabel = styled(Typography)(() => ({
    color: 'red',
    fontSize: '0.875rem', // Adjust the font size as needed
    fontWeight: 500,
    marginBottom: '15px', // Add margin as needed
    textAlign: 'left',
  }));

  const [errors, setErrors] = useState({
    category_name: '',
    title: '',
    price: '',
    short_desc: '',
    description: '',
  });
  const [editServiceDetails, setEditProfileDetails] = useState<any | null>(null);

  const handleChange = (prop: any) => (event: any) => {
    setEditProfileDetails({ ...editServiceDetails, [prop]: event.target.value });
    setErrors({ ...errors, [prop]: '' });
  };

  return (
    <Box sx={{ width: '100%' }} className="dynamic-form">
      <StyledCard sx={{ mb: 2 }}>
        <Grid container sx={{ ml: 3 }}>
          <Grid item xs={12} md={4} sx={{ padding: '0 10px' }}>
            <InputLabel htmlFor="category_name"> Category Name</InputLabel>
            <Select
              id="category_name"
              value={selectedServiceEditDetails.category_name}
              onChange={handleChange('category_name')}
              // onChange={(e) => handleQuestionChange(questionIndex, 'type', e.target.value)}
              required
              sx={{ width: '100%', mt: 1 }}
            >
              <MenuItem value="Landscaping">Landscaping</MenuItem>
              <MenuItem value="Building">Building</MenuItem>
              <MenuItem value="Building">Interior</MenuItem>
            </Select>

            <ErrorLabel>{errors.category_name}</ErrorLabel>
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: '0 10px' }}>
            <InputLabel htmlFor="title"> Service name</InputLabel>
            <InputField
              id="title"
              type="text"
              fullWidth
              margin="normal"
              value={selectedServiceEditDetails.title}
              onChange={handleChange('title')}
            />
            <ErrorLabel>{errors.title}</ErrorLabel>
          </Grid>

          {/* <Grid item xs={12} md={4} sx={{ padding: '0 10px' }}>
            <InputLabel htmlFor="price"> Starting from</InputLabel>
            <InputField
              id="price"
              type="text"
              fullWidth
              margin="normal"
              value={selectedServiceEditDetails.title}
              onChange={handleChange('price')}
            />
            <ErrorLabel>{errors.price}</ErrorLabel>
          </Grid> */}
        </Grid>
      </StyledCard>
      <Card>
        <StyledCard sx={{ boxShadow: 'none' }}>
          {/* <img src={selectedServiceEditDetails.image} alt="Company Logo" style={{ width: '300px', height: '300px' }} /> */}
          <div className="Rectangle-90">
            <img
              src={selectedServiceEditDetails.image}
              alt="Profile Picture"
              style={{ width: '300px', height: '300px' }}
            />

            <input
              type="file"
              id="file-input"
              // onChange={(e) => handleFileChange(e, field)}
              accept=".png, .jpg, .jpeg"
              required
            />
            <label htmlFor="file-input" className="Drag-and-drop-or-Choose-file-to-upload label-style">
              <Typography variant="body1" component="span" px={1} color="primary">
                Upload Picture
              </Typography>
            </label>
          </div>
          <CardContent sx={{ width: '100%' }}>
            <CardContentWrapperInner>
              <Grid item xs={12} md={12} sx={{ padding: '0 10px' }}>
                <InputLabel htmlFor="title"> Short Description</InputLabel>
                <Textarea
                  aria-label="empty textarea"
                  placeholder="Enter your text here"
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '20px',
                    borderRadius: '8px',
                    marginTop: '8px',
                    marginBottom: '16px',
                  }}
                />
                <ErrorLabel>{errors.title}</ErrorLabel>
              </Grid>
            </CardContentWrapperInner>
            <Grid item xs={12} md={12} sx={{ padding: '0 10px' }}>
              <InputLabel htmlFor="title"> Description</InputLabel>
              <Textarea
                aria-label="empty textarea"
                placeholder="Enter your text here"
                style={{
                  width: '100%',
                  height: '150px',
                  padding: '20px',
                  borderRadius: '8px',
                  marginTop: '8px',
                  marginBottom: '16px',
                }}
              />
              <ErrorLabel>{errors.title}</ErrorLabel>
            </Grid>
          </CardContent>
        </StyledCard>
        <>
          <Divider flexItem />
          <Box sx={{ textAlign: 'right', py: 5, mr: 2 }}>
            <Button
              sx={{ borderRadius: '30px', mr: 2, padding: '10px 20px', color: '#1F261F' }}
              onClick={() => {
                setEditService(false);
                // setEditProfile(false);
                // setEditProfileDetails(null);
                // setErrors({
                //   first_name: '',
                //   last_name: '',
                //   phone_number: '',
                //   phone_number_prefix: '',
                //   // image: Emp1,
                //   dob: '',
                //   gender: '',
                //   addresses: '',
                //   experience: '',
                // });
              }}
            >
              Cancel
            </Button>

            <LoginButton
              variant="contained"
              sx={{ borderRadius: '30px' }}
              // onClick={saveProfileData}
            >
              Save Changes
            </LoginButton>
          </Box>
        </>
      </Card>
    </Box>
  );
};

export default ServiceCardEdit;
