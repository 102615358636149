import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import {
  Button,
  IconButton,
  Typography,
  Box,
  styled,
  Card,
  Chip,
  CardContent,
  CardActions,
} from "@mui/material"

import AddIcon from "@mui/icons-material/Add"
import { ToastContainer, toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { RootState, setRole, setUserDetail } from "../../store"
import Header from "../../components/core/header"
import { useNavigate } from "react-router-dom"

import AddressModal from "./new-address"
import EditAddressModal from "./edit-address"

const editIcon = require("../../assets/icons/edit.svg").default
const deleteIcon = require("../../assets/icons/delete.svg").default

interface Address {
  id: string
  title: string
  address: string
  city: string
  state: string
  country: string
  pincode: string
  address_type: string
}

// interface AddressModalProps {
//   open: boolean;
//   //setIsAddressModalOpen: Dispatch<SetStateAction<boolean>>;
//   selectedAddress: any;
//   //setSelectedAddress: any;
// }

interface FormData {
  title: string
  street: string
  city: string
  state: string
  country: string
  pincode: string
}

const ErrorLabel = styled(Typography)(() => ({
  color: "red",
  fontSize: "0.875rem", // Adjust the font size as needed
  fontWeight: 500,
  marginBottom: "15px", // Add margin as needed
  textAlign: "left",
}))

const ActionButton = styled(Button)`
  margin-left: 20px;
  text-align: left;
  height: 48px;
  color: var(--btn-text);
  background: var(--primary);
  font-size: 16px;
  font: Kufam;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 20px;
  &:hover {
    background: var(--hover); /* Change the background color on hover */
  }

  &:active {
    background: var(--primary); /* Change the background color on click */
  }
`

const AddressesContainer = styled(Box)`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  ${(props) => props.theme.breakpoints.down("lg")} {
    justify-content: center;
  }
`

const AddressCardWrapper = styled(Card)`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem;
  box-shadow: 0px 0px 2rem #ccc;
`

const StyledCardActions = styled(CardActions)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ManageAddress: React.FC<any> = ({}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userDetail = useSelector((state: RootState) => state.userDetail)

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)

  const [isEditAddressModalOpen, setEditIsAddressModalOpen] = useState(false)

  const [selectedAddress, setSelectedAddress] = useState<any | null>(null)

  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT
  const accessToken = localStorage.getItem("access_token")
  const [selectedAddressValue, setSelectedAddressValue] = useState("")
  const [addressList, setAddressList] = useState<Address[]>([])
  const [addNewAddress, setAddNewAddress] = useState<boolean>(false)

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token")
    const role = localStorage.getItem("role")
    if (accessToken) {
      // dispatch(setAuthenticated(true));
      dispatch(setRole(role ? role : ""))
      fetchData(accessToken)
    }
  }, [])

  async function fetchData(accessToken: string) {
    try {
      const response = await fetch(`${apiEndpoint}/users/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })

      const data = await response.json()
      if (response.ok) {
        dispatch(setUserDetail(data))
        const userDetail = data
        if (userDetail?.addresses.length > 0) {
          let updatedAddressList =
            userDetail?.addresses && userDetail?.addresses?.length > 0
              ? bringPrimaryToTop(userDetail.addresses)
              : []

          setAddressList(updatedAddressList)
          const primaryAddress = userDetail?.addresses.find(
            (address: Address) => address.address_type === "PRIMARY"
          )
          setSelectedAddressValue(primaryAddress ? primaryAddress.id : null)
          setIsAddressModalOpen(false)
        } else {
          setIsAddressModalOpen(true)
        }
        return
      } else {
        console.error("Login failed")
        return
      }
    } catch (error) {
      console.error("Error during login:", error)
      return
    }
  }

  const deleteAddress = async (id: string) => {
    let url: string = `${apiEndpoint}/users/addresses/${id}`

    const response = await fetch(url, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })

    const data = await response.json()

    if (data && data.success) {
      const prevAddressListParsed = [...addressList]
      const updatedAddressList = prevAddressListParsed.filter(
        (add: any) => add.id !== id
      )

      setAddressList(updatedAddressList)

      toast.success(`Address removed successfully`, {
        position: "top-right",
        autoClose: 5000,
      })
    } else {
      data && data.message
        ? toast.error(data.message, {
            position: "top-right",
            autoClose: 5000,
          })
        : toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
          })
    }
  }

  const bringPrimaryToTop = (array: any) => {
    return array.sort((a: any, b: any) => {
      if (a.address_type === "PRIMARY") {
        return -1 // 'PRIMARY' comes first
      } else if (b.address_type === "PRIMARY") {
        return 1 // 'PRIMARY' comes first
      } else {
        return 0 // No change in order
      }
    })
  }

  const setasDefault = async (id: string) => {
    let url: string = `${apiEndpoint}/users/addresses/${id}`

    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })

    const data = await response.json()

    if (data && data.success) {
      const addList = [...addressList]
      let updatedAddressList = addList.map((add: any) => {
        if (add.id === id) {
          add.address_type = "PRIMARY"
        } else {
          add.address_type = "SECONDARY"
        }
        return add
      })

      // updatedAddressList = bringPrimaryToTop(updatedAddressList);

      setAddressList(updatedAddressList)

      toast.success(`Updated primary address successfully`, {
        position: "top-right",
        autoClose: 5000,
      })
    } else {
      toast.warning(`Unable to update primary address`, {
        position: "top-right",
        autoClose: 5000,
      })
    }
  }

  return (
    <Box sx={{ px: { xs: 0, sm: 0, md: 0, lg: 3, xl: 3 } }}>
      <ToastContainer />
      <Header>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "5px 0px",
            }}>
            <React.Fragment>
              <Typography
                component='div'
                sx={{ fontSize: "32px", color: "#000000" }}>
                Manage Addresses
              </Typography>
            </React.Fragment>
          </Box>
        </Box>
      </Header>
      <Box sx={{ maxWidth: "100%" }}>
        <Button
          variant='outlined'
          onClick={() => {
            setIsAddressModalOpen(true)
          }}
          color='primary'
          sx={{
            my: 2,
            textAlign: "center",
          }}>
          <AddIcon /> <span style={{ marginRight: 6 }}>Add new address</span>
        </Button>
      </Box>
      <React.Fragment>
        {addressList.length ? (
          <AddressesContainer sx={{ flewidth: "100%" }}>
            {addressList?.map((address) => (
              <AddressCard
                address={address}
                deleteAddress={deleteAddress}
                setSelectedAddress={setSelectedAddress}
                setEditModalOpen={setEditIsAddressModalOpen}
                setDefault={setasDefault}
              />
            ))}
          </AddressesContainer>
        ) : (
          <Typography
            sx={{
              bgcolor: "none",
              textAlign: "left",
            }}>
            Looks like you haven't added any address. Click on 'Add New Address'
            button above to continue.
          </Typography>
        )}
      </React.Fragment>
      <AddressModal
        open={isAddressModalOpen}
        setIsAddressModalOpen={setIsAddressModalOpen}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        addressList={addressList}
        setAddressList={setAddressList}
      />
      <EditAddressModal
        open={isEditAddressModalOpen}
        setIsAddressModalOpen={setEditIsAddressModalOpen}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        addressList={addressList}
        setAddressList={setAddressList}
      />
    </Box>
  )
}

export default ManageAddress

const AddressCard = (props: {
  address: any
  deleteAddress: Function
  setSelectedAddress: Function
  setEditModalOpen: Function
  setDefault: Function
}) => {
  const {
    address,
    deleteAddress,
    setSelectedAddress,
    setEditModalOpen,
    setDefault,
  } = props
  const fullAddress = `${address.address}, ${address.city}, ${address.state}, ${address.country}, ${address.pincode}`
  return (
    <AddressCardWrapper variant='outlined'>
      <CardContent sx={{ pb: 0 }}>
        <>
          <Typography fontSize='medium'>{address.title}</Typography>
        </>
        <>
          <Typography
            fontSize='small'
            sx={{ fontWeight: "normal", color: "rgba(0, 0, 0, 0.6)", pt: 1 }}>
            {fullAddress}
          </Typography>
        </>
      </CardContent>
      <StyledCardActions sx={{ mb: 1 }}>
        <Box>
          {address.address_type === "PRIMARY" && (
            <Chip
              size='small'
              color='primary'
              sx={{ px: 1, ml: 1, color: "var(--btn-text)" }}
              label='Default'
            />
          )}
        </Box>
        <Box>
          {address.address_type !== "PRIMARY" && (
            <Button
              sx={{ py: 0 }}
              variant='outlined'
              size='small'
              aria-label='Set Default'
              onClick={() => setDefault(address.id)}>
              {" "}
              Set Default{" "}
            </Button>
          )}
          <IconButton
            aria-label='Remove'
            onClick={() => {
              setSelectedAddress(address)
              setEditModalOpen(true)
            }}>
            {" "}
            <img src={editIcon} />
          </IconButton>
          {address.address_type !== "PRIMARY" && (
            <IconButton
              aria-label='Remove'
              onClick={() => {
                deleteAddress(address.id)
              }}>
              {" "}
              <img src={deleteIcon} />
            </IconButton>
          )}
        </Box>
      </StyledCardActions>
    </AddressCardWrapper>
  )
}
