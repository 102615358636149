import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  IconButton,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Box,
  TextField,
  Grid,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState, setRole, setUserDetail } from '../../store';
import Map from './map';
import { useDispatch } from 'react-redux';

interface Address {
  id: number;
  title: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  address_type: string;
}

interface AddressModalProps {
  open: boolean;
  setIsAddressModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedAddress: any;
  setSelectedAddress: any;
}

interface FormData {
  title: string;
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}

interface FormErrorData {
  title: string;
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  location: string;
}

const ErrorLabel = styled(Typography)(() => ({
  color: 'red',
  fontSize: '0.875rem', // Adjust the font size as needed
  fontWeight: 500,
  marginBottom: '15px', // Add margin as needed
  textAlign: 'left',
}));

const ActionButton = styled(Button)`
  margin-left: 20px;
  text-align: left;
  height: 48px;
  color: var(--btn-text);
  background: var(--primary);
  font-size: 16px;
  font: Kufam;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 20px;
  &:hover {
    background: var(--hover); /* Change the background color on hover */
  }

  &:active {
    background: var(--primary); /* Change the background color on click */
  }
`

const AddressModal: React.FC<AddressModalProps> = ({
  open,
  setIsAddressModalOpen,
  selectedAddress,
  setSelectedAddress,
}) => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state: RootState) => state.userDetail);
  const bookingDetails = useSelector((state: RootState) => state.bookingDetail);

  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const accessToken = localStorage.getItem('access_token');
  const [selectedAddressValue, setSelectedAddressValue] = useState('');
  const [addressList, setAddressList] = useState<Address[]>([]);
  const [addNewAddress, setAddNewAddress] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    street: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  });
  const [errors, setErrors] = useState<FormErrorData>({
    title: '',
    street: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    location: '',
  });

  /**
   * Map related state values
   */
  const DefaultLocation = { lat: 13.067439, lng: 80.237617 };
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name as string]: value as string,
    }));
    setErrors({ ...errors, [name as string]: '' });
  };

  async function fetchData(accessToken: string) {
    try {
      const response = await fetch(`${apiEndpoint}/users/profile`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        dispatch(setUserDetail(data));
        return;
      } else {
        console.error('Login failed');
        return;
      }
    } catch (error) {
      console.error('Error during login:', error);
      return;
    }
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const role = localStorage.getItem('role');
    if (accessToken) {
      // dispatch(setAuthenticated(true));
      dispatch(setRole(role ? role : ''));
      fetchData(accessToken);
    }
  }, []);

  const saveAddress = async (id: string) => {
    if (bookingDetails && id) {
      let url: string = `${apiEndpoint}/bookings/${bookingDetails.id}/save_booking/`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ address: id }),
      });
      const data = await response.json();

      // if (data) {
      //   toast.success(`Address saved for the booking`, {
      //     position: 'top-right',
      //     autoClose: 5000,
      //   });
      // }
    }
  };

  const handleSubmit = async () => {
    if (addNewAddress) {
      if (handleValidation()) {
        try {
          let url: string = `${apiEndpoint}/users/addresses`;

          const requestBody: any = {
            title: formData.title,
            address: formData.street,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            pincode: formData.pincode,
            latitude: location.lat,
            longitude: location.lng,
          };

          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(requestBody),
          });
          const data = await response.json();
          if (data) {
            setAddressList((prevAddresses) => [data.address, ...prevAddresses]);

            setFormData({
              title: '',
              street: '',
              city: '',
              state: '',
              country: '',
              pincode: '',
            });
            setAddNewAddress(false);
            setIsAddressModalOpen(false);
            setSelectedAddress(data.address);
            setSelectedAddressValue(data.address.id);
            saveAddress(data.address.id); // Save address to the booking
          } else {
            toast.warning(`Unable to add new address. Please try again`, {
              position: 'top-right',
              autoClose: 5000,
            });
          }
        } catch (error) {
          console.error('Error fetching Services:', error);
        }
      }
    } else {
      const addressChanged = addressList?.filter(
        (address) => address.id.toString() === selectedAddressValue.toString(),
      );
      setSelectedAddress(addressChanged && addressChanged[0]);
      saveAddress(addressChanged && addressChanged[0].id.toString()); // Save address to the booking
      setIsAddressModalOpen(false);
    }
  };

  const handleValidation = () => {
    const newErrors = {
      title: '',
      street: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      location: '',
    };

    if (!formData.title) {
      newErrors.title = 'Title is required';
    }

    if (!formData.street) {
      newErrors.street = 'Street is required';
    }

    if (!formData.city) {
      newErrors.city = 'City is required';
    }

    if (!formData.state) {
      newErrors.state = 'State is required';
    }
    if (!formData.country) {
      newErrors.country = 'Country is required';
    }

    if (!formData.pincode) {
      newErrors.pincode = 'Pincode is required';
    }

    if (location.lat === defaultLocation.lat || location.lng === defaultLocation.lng) {
      newErrors.location = 'Map location is required';
    }

    setErrors(newErrors);

    return (
      !newErrors.pincode &&
      !newErrors.state &&
      !newErrors.city &&
      !newErrors.street &&
      !newErrors.title &&
      !newErrors.location
    );
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAddressValue(event.target.value);
  };

  useEffect(() => {
    //API call to fetch customer address list
    if (userDetail?.addresses.length > 0) {
      setAddNewAddress(false);
      setAddressList(userDetail?.addresses && userDetail?.addresses?.length > 0 ? userDetail.addresses.reverse() : []);
      const primaryAddress = userDetail?.addresses.find((address: Address) => address.address_type === 'PRIMARY');
      setSelectedAddressValue(primaryAddress ? primaryAddress.id : null);
    } else {
      setAddNewAddress(true);
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setIsAddressModalOpen(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
          Choose or Add Address
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsAddressModalOpen(false);
              setAddNewAddress(false);
              setErrors({
                title: '',
                street: '',
                city: '',
                state: '',
                country: '',
                pincode: '',
                location: '',
              });
              setFormData({
                title: '',
                street: '',
                city: '',
                state: '',
                country: '',
                pincode: '',
              });
            }}
            sx={{ position: 'absolute', right: 2, top: 8 }}
          >
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        {!addNewAddress && (
          <React.Fragment>
            <Button
              onClick={() => {
                setAddNewAddress(true);
              }}
              color="primary"
              sx={{ px: 3, mb: 2 }}
            >
              <AddIcon sx={{ marginRight: '8px' }} /> Add New Address
            </Button>
            <Divider color="primary" />

            <RadioGroup
              value={selectedAddressValue}
              defaultValue={selectedAddressValue}
              onChange={handleAddressChange}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <List sx={{ width: '100%' }}>
                {addressList?.map((address) => (
                  <Box
                    key={address.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      borderBottom: '1px solid',
                      padding: '10px 20px',
                    }}
                  >
                    <Radio key={address.id} value={address.id} />
                    <ListItem disablePadding>
                      <ListItemText
                        primary={address.title}
                        secondary={`${address.address}, ${address.city}, ${address.state}, ${address.country}, ${address.pincode}`}
                      />
                    </ListItem>
                  </Box>
                ))}
              </List>
            </RadioGroup>
          </React.Fragment>
        )}
        {addNewAddress && (
          <Box sx={{ p: 3, mb: 2 }}>
            <Typography variant="h6" sx={{ fontSize: '16px' }}>
              Add new address
            </Typography>
            <form>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Title *"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.title}</ErrorLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Street *"
                    name="street"
                    value={formData.street}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.street}</ErrorLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="City *"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.city}</ErrorLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="State *"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.state}</ErrorLabel>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Country *"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.country}</ErrorLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Pin Code *"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.pincode}</ErrorLabel>
                </Grid>
              </Grid>
            </form>
            <Grid container style={{ padding: '0px' }}>
              <Grid item xs={12}>
                Select Location * <ErrorLabel>{errors.location}</ErrorLabel>
              </Grid>
              <Grid item xs={12}>
                <Map
                  setDefaultLocation={setDefaultLocation}
                  location={location}
                  setLocation={setLocation}
                  defaultLocation={defaultLocation}
                  DefaultLocation={DefaultLocation}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ border: 'none', mb: 3, mr: 2 }}>
        <Button
          variant="outlined"
          onClick={() => {
            if (!addNewAddress) {
              setIsAddressModalOpen(false);
            } else {
              setErrors({
                title: '',
                street: '',
                city: '',
                state: '',
                country: '',
                pincode: '',
                location: '',
              });
              setFormData({
                title: '',
                street: '',
                city: '',
                state: '',
                country: '',
                pincode: '',
              });
              setAddNewAddress(false);
            }
          }}
          color="primary"
          sx={{ padding: '10px 30px !important' }}
        >
          Cancel
        </Button>

        <ActionButton
          type="button"
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          sx={{ padding: '10px 30px !important' }}
        >
          Save
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddressModal;
