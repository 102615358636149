import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  styled,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from "@mui/material";
import {
  RootState,
  setActiveSelectedCategory,
} from "../../store";
import { useDispatch } from "react-redux";
import { Category } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SelectChangeEvent } from '@mui/material';
import ServiceCard from "./service-card-new";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Category {
  id: string;
  name: string;
  short_description: string;
  description: string;
  image_url: string;
}

interface Service {
  id: string;
  name: string;
  short_description: string;
  description: string;
  image_url: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: '18px' }}>{children}</Box>}
    </div>
  );
}

const MemoizedTabPanel = React.memo(TabPanel);

const StyledTabs = styled(({ className, ...other }: any) => (
  <Tabs
    {...other}
    classes={{
      root: className,
      flexContainer: "flexContainer",
      indicator: "indicator",
    }}
  ></Tabs>
))(({}: { theme: Theme }) => ({
  "& .MuiButtonBase-root": {
    // margin: '0 0px',
    textTransform: "capitalize",
  },
  "& .MuiTabs-indicator": {
    height: "3px",
    borderRadius: "6px 6px",
  },
}));

const MemoizedServiceList = (props: any) => {
  const { setSelectedService, activeCategory, setActiveCategory } = props;

  const activeCategoryStoreData = useSelector(
    (state: RootState) => state.activeCategory
  );

  const dispatch = useDispatch();
  // const isTabletOrSmaller = useMediaQuery("(max-width: 768px)");


  const [categories, setCategories] = useState<Category[]>([]);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0);
  const [services, setServices] = useState<Service[]>([]);

  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;

  const [defaultPageSize, setDefaultPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setActiveCategoryIndex(0);
  }, []);

  const handlePaginationChange = (event: any, page: number) => {
    // Update the current page when pagination changes
    setCurrentPage(page);
  };

  useEffect(() => {
    if (activeCategoryIndex || activeCategoryIndex === 0)
      fetchServices(activeCategoryIndex);
  }, [currentPage, activeCategoryIndex, defaultPageSize]);

  const handleChangeDropDown = (event:  SelectChangeEvent<number>) => {
    const selectedValue = event.target.value as number;

    if (selectedValue === -1) {
      setActiveCategory(null);
      dispatch(setActiveSelectedCategory(null));
    } else {
      const categoryDetail: Category = categories[selectedValue - 1];
      setActiveCategory(categoryDetail);
      dispatch(setActiveSelectedCategory(categoryDetail));
    }
    setCurrentPage(1);
    setActiveCategoryIndex(selectedValue);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const indexValue = newValue - 1;

    if (indexValue === -1) {
      setActiveCategory(null);
      dispatch(setActiveSelectedCategory(null));
    } else {
      const categoryDetail: Category = categories[indexValue];
      setActiveCategory(categoryDetail);
      dispatch(setActiveSelectedCategory(categoryDetail));
    }

    setCurrentPage(1);
    setActiveCategoryIndex(newValue);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${apiEndpoint}/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data && data.results) {
        const categoryData: Category[] = data.results;
        setCategories(categoryData);
        if (activeCategoryStoreData) {
          const activefromStore = categoryData.find(
            (item) => item.id === activeCategoryStoreData.id
          );
          const index = categoryData.findIndex(
            (obj) => obj.id === activeCategoryStoreData.id
          );
          setActiveCategoryIndex(index + 1);
          setActiveCategory(activefromStore);
          dispatch(setActiveSelectedCategory(activefromStore));
        } else {
          setActiveCategory(categoryData[0]);
          dispatch(setActiveSelectedCategory(categoryData[0]));
          setActiveCategoryIndex(0);
        }
      } else {
        setActiveCategory(null);
        setActiveCategoryIndex(-1);
        fetchServices(-1);
        dispatch(setActiveSelectedCategory(null));
      }
    } catch (error) {
      console.error("Error fetching Categories:", error);
    }
  };

  const fetchServices = async (index: number) => {
    try {
      let url: string = "";
      const actualIndex = index - 1;
      if (actualIndex === -1 || actualIndex === -2)  {
        url = `${apiEndpoint}/services?page=${currentPage}&page_size=${defaultPageSize}`;
      } else {
        const id = categories[actualIndex].id;
        url = `${apiEndpoint}/services/${id}?page=${currentPage}&page_size=${defaultPageSize}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data && data.results) {
        const serviceData: Service[] = data.results;
        setServices(serviceData);
        setTotalCount(data.count);
      } else {
        throw new Error("No services found");
      }
    } catch (error) {
      console.error("Error fetching Services:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const isLaptopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isMobileScreen = useMediaQuery('(max-width: 320px)');
  const isTabletScreen = useMediaQuery('(max-width: 768px)');
  const isSmallScreen = useMediaQuery('(max-width: 425px)');

  return (
    <Box sx={{ width: "100%" }}>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {isLaptopScreen ? (
            <Select
              value={activeCategoryIndex === 0 ? -1 : activeCategoryIndex}
              onChange={handleChangeDropDown}
            >
              <MenuItem value={-1}>All Services</MenuItem>
              {categories.map((category, index) => (
                <MenuItem key={index} value={index + 1}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
        ) : (
            <StyledTabs value={activeCategoryIndex} onChange={handleChange}>
              <Tab label="All Services" />
              {categories.map((category, index) => (
                <Tab key={index} label={category.name} />
              ))}
            </StyledTabs>
        )}
      </Box> */}
      {services.length > 0 && (
        <MemoizedTabPanel
          value={activeCategoryIndex}
          index={activeCategoryIndex}
        >
          <Grid container spacing={2} sx={{ flexDirection: "row", alignContent: "center", alignItems:"center", justifyContent:"center"}}>
            {services.map((service) => (
              <ServiceCard service={service} setSelectedService={setSelectedService}/>
            ))}
            {totalCount && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: {
                    xs: "column-reverse",
                    sm: "column-reverse",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  },
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    mr: isSmallScreen ? 0 : 3,
                    mb: isSmallScreen ? 3 : 0,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Rows per page
                  </InputLabel>
                  <Select
                    value={defaultPageSize}
                    onChange={(e) => {
                      setDefaultPageSize(Number(e.target.value));
                    }}
                    sx={{ ml: 2, height: "30px" }}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={55}>50</MenuItem>
                  </Select>
                </Box>
                <Pagination
                  variant="outlined"
                  color="primary"
                  count={Math.ceil(totalCount / defaultPageSize)}
                  page={currentPage}
                  onChange={handlePaginationChange}
                  sx={{ mt: 2, mb: 2 }}
                />
              </Grid>
            )}
          </Grid>
        </MemoizedTabPanel>
      )}
    </Box>
  );
};

const ServiceList = React.memo(MemoizedServiceList);

export default ServiceList;
