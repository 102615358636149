import axios, { AxiosInstance } from "axios";

const baseURL = process.env.REACT_APP_LOGIN_API_ENDPOINT;

export const cmsAxios: AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosInstance: AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});
axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("access_token");
    if (typeof token === "string" && config.headers && token) {
      config.headers["Authorization"] = "Bearer " + token || "";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      let role = localStorage.getItem("role");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      if (role === "vendor") window.location.replace("/vendor-login");
      else window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
