import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Fade,
  Modal,
  Typography,
  Button,
  OutlinedInput,
} from "@mui/material";
import { GetQuotationResponse, QuotationData } from "../../types";
import { deleteQuotation, editQuotation } from "../../action/timeline";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";
import { GridDeleteIcon } from "@mui/x-data-grid";

interface FinalQuotation {
  item: string;
  unit_price: number;
  quantity: number;
  total: string;
  id?: number;
}

interface TransformedObject {
  quotation_details: FinalQuotation[];
  total: string;
  taxes: number;
  notes?: string;
}

type Props = {
  quotation: boolean;
  setQuotation: (quote: boolean) => void;
  style: object;
  data: GetQuotationResponse[] | null;
  selectedService: string;
  timeLineData: Function;
};
export default function EditQuotationModal({
  quotation,
  setQuotation,
  style,
  data,
  selectedService,
  timeLineData,
}: Props) {
  const [addNewRow, setAddNewRow] = useState<number>(1);
  const [addTax, setAddTax] = useState(false);
  const [taxValue, setTaxValue] = useState<number>(0);
  const [quotationDetails, setQuotationDetails] = useState<string>("");

  useEffect(() => {
    if (data) {
      setQuotationDetails(data[0]?.notes);
    }
  }, [data]);

  useEffect(() => {
    data?.map((item) => {
      if (item.taxes) {
        setAddTax(true);
        setTaxValue(item.taxes);
      }
      return null;
    });
  }, [data]);

  const handleTaxChange = (e: any) => {
    const newValue = parseInt(e.target.value);
    setTaxValue(newValue);
  };

  const [quotationItems, setQuotationItems] = useState<QuotationData[]>([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const initialItems: QuotationData[] = data.reduce(
        (accumulator: QuotationData[], currentValue: GetQuotationResponse) => {
          if (
            currentValue.quotation_details &&
            currentValue.quotation_details.length > 0
          ) {
            return accumulator.concat(currentValue.quotation_details);
          }
          return accumulator;
        },
        []
      );
      setQuotationItems(initialItems);
      setAddNewRow(initialItems.length + 1);
    }
  }, [data]);

  const handleInputChange = (
    index: number,
    fieldName: string,
    value: string | number
  ) => {
    const updatedItems = [...quotationItems];
    if (fieldName === "item") {
      updatedItems[index] = {
        ...updatedItems[index],
        [fieldName]: value as string,
      };
    }
    if (
      (fieldName === "unit_price" || fieldName === "quantity") &&
      !isNaN(updatedItems[index][fieldName] as number)
    ) {
      updatedItems[index] = {
        ...updatedItems[index],
        [fieldName]: value as number,
      };
    }
    if (
      (fieldName === "unit_price" || fieldName === "quantity") &&
      isNaN(updatedItems[index][fieldName] as number)
    ) {
      updatedItems[index] = {
        ...updatedItems[index],
        [fieldName]: 0,
      };
    } else if (fieldName === "item") {
      updatedItems[index] = {
        ...updatedItems[index],
        [fieldName]: value as string,
      };
    }
    if (
      (fieldName === "unit_price" ||
        fieldName === "quantity" ||
        fieldName === "item") &&
      !isNaN(updatedItems[index][fieldName] as number)
    ) {
      const { unit_price, quantity } = updatedItems[index];
      updatedItems[index] = {
        ...updatedItems[index],
        total: unit_price * quantity,
      };
    }
    setQuotationItems(updatedItems);
  };

  const handleAddNewRow = () => {
    setQuotationItems([
      ...quotationItems,
      {
        id: 15,
        quotation_id: addNewRow,
        item: "",
        unit_price: 0,
        quantity: 0,
        total: 0,
      },
    ]);
    setAddNewRow(addNewRow + 1);
  };

  const total = useMemo(() => {
    let value = quotationItems
      .filter((item: any) => !isNaN(parseFloat(item.total)))
      .reduce((acc: any, item: any) => acc + parseFloat(item.total), 0);
    return value;
  }, [quotationItems]);

  const validateQuotation = () => {
    return quotationItems.every((ele: any) => (ele.item && ele.unit_price >= 0 && ele.quantity >= 0))
  }

  const handleDeleteQuotationDetail = (selectedService: any, item: any, index: any) => {
    deleteQuotation(selectedService, item.id)
    .then(() => {
      successNotification(
        "Successfully deleted the quotation details"
      );
      timeLineData(selectedService);
    })
    .catch((err) => {
      if (err.response.data.error === 'Quotation not found') {
        let _qItems = [ ...quotationItems ];
        _qItems.splice(index, 1);
        setQuotationItems([ ..._qItems ]);
      } else {
        errorNotification(err.response.data.error);
      }
    });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={quotation}
      onClose={() => setQuotation(false)}
      closeAfterTransition
      style={{ overflow: "auto" }}
    >
      <Fade in={quotation}>
        <Box sx={{...style, maxHeight: '90vh', maxWidth: '95vw', width: '700px', overflow: 'auto'}}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Quotation
          </Typography>
          <Typography
            id="transition-modal-title"
            sx={{ fontSize: 16, fontWeight: 400, mt: 2 }}
          >
            Add new row to add items and make total before sending.
          </Typography>
          <Box>
            <Box
              sx={{
                display: "flex",
                columnGap: "55px",
                rowGap: 1,
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Item
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Price
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Quantity
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Total
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                  marginLeft: "2px",
                }}
              >
                Delete
              </Typography>
            </Box>
            {quotationItems?.map((item, index) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 1,
                    rowGap: 1,
                    mt: 2,
                  }}
                >
                  <OutlinedInput
                    name="item"
                    type="text"
                    sx={{ height: 30 }}
                    value={item.item}
                    onChange={(e) => {
                      handleInputChange(index, "item", e.target.value);
                    }}
                  />
                  <OutlinedInput
                    name="unit_price"
                    sx={{ height: 30 }}
                    type="number"
                    value={item.unit_price}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "unit_price",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                  <OutlinedInput
                    name="quantity"
                    sx={{ height: 30 }}
                    value={item.quantity}
                    type="number"
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "quantity",
                        parseInt(e.target.value, 10)
                      )
                    }
                  />
                  <OutlinedInput
                    name="total"
                    type="number"
                    sx={{ height: 30 }}
                    value={item.unit_price * item.quantity}
                  />
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {handleDeleteQuotationDetail(selectedService, item, index)}}
                  >
                    <GridDeleteIcon />
                  </div>
                </Box>
              );
            })}

            {addTax && (
              <Box sx={{ display: "flex", columnGap: "8px", marginTop: "8px" }}>
                <OutlinedInput type="text" sx={{ height: 30 }} value={"Tax"} />
                <OutlinedInput
                  name="taxes"
                  sx={{ height: 30 }}
                  type="number"
                  onChange={(e) => {
                    handleTaxChange(e);
                  }}
                  value={taxValue}
                />
              </Box>
            )}
            <Button
              variant="outlined"
              onClick={handleAddNewRow}
              sx={{
                mt: 2,
              }}
            >
              + Add Items
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              float: "right",
              flexDirection: "column",
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 400,
                mt: 2,
                mb: 2,
                float: "right",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  float: "right",
                }}
              >
                {`Total: ₹`}
                {total + taxValue}
              </Box>
            </Typography>
            <Box
              sx={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
                rowGap: 1,
              }}
            >
              <Typography>Please type in your comments</Typography>
              <textarea
                style={{
                  border: "1px solid var(--primary)",
                  borderRadius: 2,
                  height: 100,
                  padding: 4,
                  resize: "none",
                }}
                onChange={(e) => {
                  setQuotationDetails(e.target.value);
                }}
                maxLength={5000}
                placeholder="Description must be 5000 characters or less."
              >
                {quotationDetails}
              </textarea>
              <div style={{ display: "-webkit-flex" }}>
                <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                  {quotationDetails &&
                    `${5000 - quotationDetails.length} characters remaining`}
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                columnGap: 2,
                justifyContent: "space-between",
                mt: 4,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setAddTax(true);
                }}
                sx={{ width: "120px" }}
              >
                Add Tax
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "120px" }}
                onClick={() => {
                  setQuotation(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "120px" }}
                onClick={() => {
                  console.log(quotationItems);
                  if (!validateQuotation()) {
                    errorNotification(
                      "Make sure item, quantity and price are populated in all rows."
                    );
                    return;
                  }
                  let id = data?.map((item) => item.id);
                  const transformedObject: TransformedObject =
                    quotationItems &&
                    quotationItems.length > 0 &&
                    quotationItems.reduce(
                      (acc: any, curr) => {
                        if (curr.id === 0 || curr.id === undefined) {
                          const { quotation_id, ...newRow } = curr;
                          acc.quotation_details.push(newRow as any);
                        } else {
                          if (!acc.quotation_id) {
                            acc.quotation_id = id && id[0];
                          }
                          const newItem: FinalQuotation = {
                            item: curr.item,
                            unit_price: curr.unit_price,
                            quantity: curr.quantity,
                            total: (curr.unit_price * curr.quantity).toFixed(2),
                          };
                          if (curr.id !== 15) {
                            newItem.id = curr.id;
                          }
                          acc.quotation_details.push(newItem);
                        }
                        return acc;
                      },
                      { quotation_details: [], total: "0" }
                    );

                  transformedObject.total = transformedObject.quotation_details
                    .reduce((total, item) => {
                      return total + parseFloat(item.total);
                    }, 0)
                    .toFixed(2);
                  transformedObject.taxes = taxValue;
                  transformedObject.notes =
                    quotationDetails.length > 0 ? quotationDetails : "";

                  editQuotation(selectedService, transformedObject)
                    .then((res) => {
                      setQuotation(false);
                      successNotification("Successfully updated the quotation");
                      timeLineData(selectedService);
                    })
                    .catch((err) => {
                      errorNotification(err.response.data.detail);
                    });
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
