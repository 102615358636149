import {
  Box,
  Fade,
  Modal,
  Typography,
  Button,
  OutlinedInput,
} from "@mui/material";
import ReadMore from "../../components/shared/readmore-readless";
import { GetQuotationResponse } from "../../types";

type Props = {
  viewQuotation: boolean;
  setViewQuotation: (view: false) => void;
  style: object;
  data: GetQuotationResponse[] | null;
};

export default function ViewQuotationModal({
  viewQuotation,
  setViewQuotation,
  style,
  data,
}: Props) {
  const quotationDetails = data?.map((item) => item.quotation_details);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={viewQuotation}
      onClose={() => setViewQuotation(false)}
      closeAfterTransition
      style={{ overflow: "auto", padding: "18px" }}
    >
      <Fade in={viewQuotation}>
       
        <Box sx={{...style, maxHeight: '90vh', maxWidth: '95vw', width: '900px', overflow: 'auto'}}> 
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Quotation
          </Typography>

          <Box>
            <Box
              sx={{
                display: "grid",
                columnGap: 1,
                rowGap: 1,
                gridTemplateColumns: "2fr 1fr 1fr", 
                padding: "8px", // Space around header
                backgroundColor: "#00A4E2",
                borderRadius: "5px",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#ffffff",
                  textAlign: "left",
                }}
              >
                Item
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#ffffff",
                  textAlign: "right",
                }}
              >
                Price
              </Typography>
              
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#ffffff",
                  textAlign: "right",
                }}
              >
                Amount
              </Typography>
            </Box>

            {quotationDetails?.map((item, i) =>
              item.map((allValues) => {
                return (
                  <Box
                    sx={{
                      display: "grid",
                      overflowY: 'auto',
                      columnGap: 1,
                      rowGap: 1,
                      gridTemplateColumns: "2fr 1fr 1fr", 
                      padding: "8px", 
                      borderBottom: "1px solid #00A4E2", 
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                        {allValues.item}
                      </Typography>
                      <Typography sx={{ fontSize: 12, color: "#888" }}>
                        Qty: {allValues.quantity}
                      </Typography>
                    </Box>
                    <Typography
                      
                      sx={{
                        height: 30,
                        textAlign: "right", 
                        fontWeight: 550,
                        padding: "0px",
                        
                      }}
                    >
                      {allValues.unit_price.toLocaleString()}
                    </Typography>
                    
                    <Typography
                      // name="total"
                      sx={{
                        height: 30,
                        textAlign: "right",
                        fontWeight: 550,
                        padding: "0px",
                        // backgroundColor: "#fafafa",
                      }}
                    >{allValues.total.toLocaleString()}</Typography>
                  </Box>
                );
              })
            )}

            <Box sx={{ display: "flex", justifyContent: "space-between", margin: "8px 10px 0 10px" }}>
              {/* <OutlinedInput
                type="text"
                sx={{ height: 30, fontWeight: "bold", textAlign: "center" }}
                value={"Tax"}
                disabled
              />
              <OutlinedInput
                name="taxes"
                sx={{ height: 30, fontWeight: "bold", textAlign: "center" }}
                type="number"
                value={data?.map((item) => item.taxes)}
                disabled
              /> */}
              <Typography sx={{ height: 30, fontWeight: "bold", textAlign: "left" }}>
                Tax
              </Typography>
              <Typography sx={{ height: 30, fontWeight: "bold", textAlign: "right" }}>
                {data?.map((item) => item.taxes.toLocaleString())}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end", // Align total to the right
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  mt: 2,
                  mb: 2,
                }}
              >
                {data?.map((item) => {
                  if (item.total)
                    return (
                      <Box sx={{ textAlign: "right" }}>
                        Total: <span style={{ marginLeft: "10px" }}>₹ {(item.total + item.taxes).toLocaleString()}</span>
                      </Box>
                    );
                })}
              </Typography>
            </Box>
            {/* </Box> */}

            <Box
              sx={{
                gridRow: "1",
                gridColumn: "span 5",
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>Comments</Typography>
              <Box sx={{ fontSize: 14, color: "#a6a6a6", lineHeight: { sm: "20px", md: "25px" }, textOverflow: "ellipsis" }}>
                {data ? <ReadMore>{data[0]?.notes}</ReadMore> : ""}
              </Box>
            </Box>

            <Box
              // sx={{
              //   display: "flex",
              //   columnGap: 2,
              //   justifyContent: "space-between",
              //   marginLeft: "auto",
              //   mt: 4,
              // }}
              sx={{
                display: "flex",
                justifyContent: "flex-end", // Pushes the button to the right
                mt: 4,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setViewQuotation(false);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
