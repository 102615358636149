import { Box, Button, Fade, Grid, Modal, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { completeWork } from "../../action/timeline";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";
import ImageSliderModal from "../../components/shared/image-slider";

type Props = {
  completeWorkModal: boolean;
  setCompeleteWorkModal: (completeWorkModal: boolean) => void;
  style: object;
  selectedService: string;
  timeLineData: Function;
  handleFileChange: Function;
  setIsImageSliderModalOpen: any;
  setImagePreviewFlag: (preview: string) => void;
  fileInputRef: React.MutableRefObject<null>;
  isImageUpdated: boolean;
  selectedWorkFileDisplay: string[];
  setSelectedWorkFileDisplay: (response: any[]) => void;
  selectedFile: string[];
  setSelectedFile: any;
  setIsImageUpdated: (image: boolean) => void;
  isImageSliderModalOpen: boolean;
  startIndex: number;
  setStartIndex: any;
  deleteImage: () => void;
  imagePreviewFlag: string;
};

export default function CompleteWorkModal({
  completeWorkModal,
  setCompeleteWorkModal,
  style,
  selectedService,
  timeLineData,
  handleFileChange,
  setIsImageSliderModalOpen,
  setStartIndex,
  setImagePreviewFlag,
  fileInputRef,
  isImageUpdated,
  selectedWorkFileDisplay,
  setSelectedWorkFileDisplay,
  setSelectedFile,
  setIsImageUpdated,
  isImageSliderModalOpen,
  startIndex,
  deleteImage,
  imagePreviewFlag,
  selectedFile,
}: Props) {
  const [details, setDetails] = useState<string>("");

  useEffect(() => {
    if (selectedService) {
      setSelectedWorkFileDisplay([]);
    }
  }, [selectedService]);

  const handleUpload = async () => {
    const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
    const accessToken = localStorage.getItem("access_token");
    try {
      const formData = new FormData();

      const filteredArray = selectedFile.filter(
        (item: any) => typeof item === "object"
      );
      for (let i = 0; i < filteredArray.length; i++) {
        formData.append("images", filteredArray[i]);
      }
      const randomNo = Math.floor(Math.random() * 900000) + 100000;
      formData.append("job_progress_details", randomNo.toString());
      formData.append("detail_type", "work_completed");

      const response = await axios.put(
        `${apiEndpoint}/bookings/${selectedService}/upload_booking_images/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response && response.data) {
        setSelectedWorkFileDisplay(response.data.data);
        setSelectedFile([]);
        setIsImageUpdated(false);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={completeWorkModal}
      onClose={() => setCompeleteWorkModal(false)}
      closeAfterTransition
      style={{ overflow: "auto" }}
    >
      <Fade in={completeWorkModal}>
        <Box sx={{ ...style, width: '620px'}}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: 28, fontWeight: 600, marginBottom: "12px" }}
          >
            Work Complete
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridAutoColumns: "1fr",
              gap: 3,
            }}
          >
            <Box
              sx={{
                gridRow: "1",
                gridColumn: "span 5",
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
              }}
            >
              <Grid container sx={{ mb: 1, bottom: 0 }}>
                <Grid item xs={9}>
                  <Typography sx={{ fontSize: 15,  mb: 2 }}>
                    Please type in your comments (optional)
                  </Typography>
                  <textarea
                    style={{
                      border: "1px solid var(--primary)",
                      borderRadius: 2,
                      height: 140,
                      padding: 4,
                      resize: "none",
                      width: "100%",
                      marginTop: "5px",
                    }}
                    onChange={(e) => {
                      setDetails(e.target.value);
                    }}
                    maxLength={5000}
                    placeholder="Description must be 5000 characters or less."
                  >
                    {details}
                  </textarea>
                  <div style={{ display: "-webkit-flex" }}>
                    <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                      {details &&
                        `${5000 - details.length} characters remaining`}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2} sx={{ px: 2, position: "relative" }}>
                  <Box
                    sx={{
                      gridRow: "1",
                      gridColumn: "span 1",
                      alignContent: "center",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      rowGap: 2,
                    }}
                  >
                    <Typography>Photos</Typography>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 2fr)",
                      }}
                    >
                      {selectedWorkFileDisplay &&
                        selectedWorkFileDisplay?.length > 0 &&
                        selectedWorkFileDisplay
                          .slice(0, 3)
                          .map((file: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <img
                                  style={{
                                    width: "65px",
                                    height: "65px",
                                    margin: "5px",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    border: "1px solid #C1D0E0",
                                  }}
                                  onClick={() => {
                                    setIsImageSliderModalOpen(true);
                                    setStartIndex(index);
                                    setImagePreviewFlag("edit");
                                  }}
                                  src={file}
                                  alt=""
                                />
                                {selectedWorkFileDisplay?.length > 3 &&
                                  index === 2 && (
                                    <label
                                      style={{
                                        width: "65px",
                                        height: "65px",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        borderRadius: "8px",
                                        textAlign: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "white",
                                        cursor: "pointer",
                                        margin: "5px",
                                        position: "absolute",
                                        top: "123px",
                                        border: "1px solid #C1D0E0",
                                      }}
                                      onClick={() => {
                                        setIsImageSliderModalOpen(true);
                                        setStartIndex(2);
                                        setImagePreviewFlag("edit");
                                      }}
                                    >
                                      + {selectedWorkFileDisplay?.length - 3}
                                    </label>
                                  )}
                              </React.Fragment>
                            );
                          })}

                      <label
                        htmlFor="fileInput"
                        style={{
                          width: "65px",
                          height: "65px",
                          backgroundColor: "#1F261F",
                          borderRadius: "10px",
                          textAlign: "center",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          color: "white",
                          cursor: "pointer",
                          margin: "5px",
                          border: "1px solid #C1D0E0",
                        }}
                      >
                        + Add
                      </label>
                      <input
                        id="fileInput"
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={(event) => handleFileChange(event)}
                        accept=".png, .jpg, .jpeg"
                      />
                    </Box>
                  </Box>
                </Grid>
                <ImageSliderModal
                  isImageSliderModalOpen={isImageSliderModalOpen}
                  onImageSliderModalClose={setIsImageSliderModalOpen}
                  images={selectedWorkFileDisplay}
                  startIndex={startIndex}
                  setStartIndex={setStartIndex}
                  imagePreviewFlag={imagePreviewFlag}
                  flag="string"
                  deleteImage={deleteImage}
                />

                <Grid xs={12} sx={{ mt: 4 }}>
                  <Button
                    variant="contained"
                    sx={{ float: "right", marginLeft: "auto" }}
                    onClick={async () => {
                      if (isImageUpdated) await handleUpload();
                      let body = {
                        details: details,
                      };

                      await completeWork(selectedService, body)
                        .then(() => {
                          successNotification("Successfully work completed");
                          timeLineData(selectedService);
                          setSelectedWorkFileDisplay([]);
                          setDetails("");
                          setCompeleteWorkModal(false);
                        })
                        .catch((err) => {
                          errorNotification(err.response.data.message);
                        });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ float: "right", marginLeft: "auto", marginRight: 2 }}
                    onClick={async () => {
                      setCompeleteWorkModal(false);
                    }}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
