import { Box, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { BookingDetailTimelineResponse } from "../../types";
import { StepperType } from "../../types/StepperType";
import dayjs from "dayjs";
import ImageSliderModal from "../../components/shared/image-slider";
import ReadMore from "../../components/shared/readmore-readless";

type Props = {
  bookingDetails: BookingDetailTimelineResponse;
  imgInspectionSlider: boolean;
  setImgInspectionSlider: Dispatch<SetStateAction<boolean>>;
  imagePreviewFlag: string;
  setImagePreviewFlag: any;
  startIndex: number;
  setStartIndex: Dispatch<SetStateAction<number>>;
};
export const InsepectionDetails = ({
  bookingDetails,
  imgInspectionSlider,
  setImgInspectionSlider,
  imagePreviewFlag,
  setImagePreviewFlag,
  startIndex,
  setStartIndex,
}: Props) => {
  let insepectionDetails: StepperType = {
    label: (
      <Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "var(--secondary)",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {`${bookingDetails ? bookingDetails?.inspection_complete.label : ""}`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: 400, color: "#1F261F" }}
          >
            {bookingDetails &&
              dayjs(bookingDetails?.inspection_complete.created_at).format(
                "DD MMM YYYY"
              )}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "#A7A7A7", fontWeight: 400 }}
          >
            {bookingDetails &&
              dayjs(bookingDetails?.inspection_complete.created_at).format(
                "hh:mm A"
              )}
          </Typography>
        </Box>
      </Box>
    ),
    roles: ["user", "vendor"],
    description: (
      <Box
        sx={{
          display: "flex",
          columnGap: "50px",
          justifyContent: "space-between",
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'row',
            xl: 'row'
          },
          alignItems: "baseline"
        }}
      >
        <Typography sx={{ fontSize: "14px" }} color="text.primary" gutterBottom>
          <ReadMore>{`Vendor : ${
            bookingDetails &&
            bookingDetails.inspection_complete.inspection_details
          }`}</ReadMore>
        </Typography>
        <Box>
          {bookingDetails?.inspection_complete &&
            bookingDetails?.inspection_complete?.inspection_images?.length >
              0 && (
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    Photos
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 2fr)",
                    }}
                  >
                    {bookingDetails?.inspection_complete?.inspection_images &&
                      bookingDetails?.inspection_complete?.inspection_images
                        .slice(0, 3)
                        .map((file: any, index: number) => {
                          return (
                            <React.Fragment key={file.image_id}>
                              <img
                                style={{
                                  width: "65px",
                                  height: "65px",
                                  margin: "5px",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                                src={file.image_url}
                                alt=""
                                onClick={() => {
                                  setImgInspectionSlider(true);
                                  setStartIndex(index);
                                  setImagePreviewFlag("view");
                                }}
                              />
                              {bookingDetails?.inspection_complete
                                ?.inspection_images.length > 3 &&
                                index === 2 && (
                                  <Box
                                    sx={{
                                      position: "relative",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    <label
                                      style={{
                                        width: "65px",
                                        height: "65px",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        borderRadius: "8px",
                                        textAlign: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "white",
                                        cursor: "pointer",
                                        margin: "5px",
                                      }}
                                      onClick={() => {
                                        setImgInspectionSlider(true);
                                        setStartIndex(3);
                                        setImagePreviewFlag("view");
                                      }}
                                    >
                                      +{" "}
                                      {bookingDetails?.inspection_complete
                                        ?.inspection_images.length - 3}
                                    </label>
                                  </Box>
                                )}
                            </React.Fragment>
                          );
                        })}
                  </Box>
                </Box>

                <ImageSliderModal
                  isImageSliderModalOpen={imgInspectionSlider}
                  onImageSliderModalClose={setImgInspectionSlider}
                  images={
                    imgInspectionSlider
                      ? bookingDetails?.inspection_complete?.inspection_images
                      : []
                  }
                  startIndex={startIndex}
                  setStartIndex={setStartIndex}
                  imagePreviewFlag={imagePreviewFlag}
                  flag="object"
                />
              </React.Fragment>
            )}
        </Box>
      </Box>
    ),
  };
  return insepectionDetails;
};
