import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  BookingDetailTimelineResponse,
  EmployeeDetails,
  selectedSlotsColor,
  selectedTimeSlots,
  timeSlot,
} from "../../types";
import { StepperType } from "../../types/StepperType";
import dayjs from "dayjs";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";
import {
  saveTimeSlot,
  deleteEmployee,
  confirmTimeAndEmployee,
} from "../../action/timeline";

type Props = {
  bookingDetails: BookingDetailTimelineResponse;
  role: string | null;
  timeSlotSlicedData: false | selectedTimeSlots[] | null;
  bgColor: selectedSlotsColor;
  setAssignSlot: (assign: timeSlot) => void;
  timeLineData: Function;
  selectedService: string;
  employeeSlicedData: false | EmployeeDetails[] | null;
  setAssignEmployeeModal: (assignEmployee: boolean) => void;
  getInitials: (fullName: string) => string;
};
export const SelectSlotByVendor = ({
  bookingDetails,
  role,
  timeSlotSlicedData,
  bgColor,
  setAssignSlot,
  timeLineData,
  selectedService,
  employeeSlicedData,
  setAssignEmployeeModal,
  getInitials,
}: Props) => {
  let selectSlotByVendor: StepperType = {
    label: (
      <Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "var(--secondary)",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {`${
            bookingDetails &&
            bookingDetails.inspection_scheduled.hasOwnProperty(
              "selected_slot_detail"
            ) &&
            bookingDetails.inspection_scheduled.selected_slot_detail?.length > 0
              ? "Inspection Scheduled"
              : bookingDetails &&
                bookingDetails.slots_provided.hasOwnProperty("slots_detail") &&
                bookingDetails?.slots_provided.slots_detail?.length > 0
              ? "Slots Provided"
              : ""
          }`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: 400, color: "#1F261F" }}
          >
            {bookingDetails?.slots_provided &&
              dayjs(bookingDetails?.slots_provided.created_at).format(
                "DD MMM YYYY"
              )}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "#A7A7A7", fontWeight: 400 }}
          >
            {bookingDetails?.slots_provided &&
              dayjs(bookingDetails?.slots_provided.created_at).format(
                "hh:mm A"
              )}
          </Typography>
        </Box>
      </Box>
    ),
    roles: ["vendor", "user"],
    description: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "25px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              columnGap: "10px",
              rowGap: "12px",
            }}
          >
            {bookingDetails?.inspection_scheduled.hasOwnProperty(
              "selected_slot_detail"
            ) ? (
              <Typography
                sx={{ fontSize: "14px", fontWeight: 400 }}
              >{`Selected Slot and Employee`}</Typography>
            ) : role === "user" ? (
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                The Selected Slots
              </Typography>
            ) : role === "vendor" ? (
              <Typography
                sx={{ fontSize: "14px", fontWeight: 400 }}
              >{`Select Slot and Employee`}</Typography>
            ) : (
              ""
            )}

            <Box
              sx={{
                display: "flex",
                columnGap: "20px",
                width: "100%",
                overflowY: "auto"
              }}
            >
              {timeSlotSlicedData &&
                timeSlotSlicedData.map((item: any) => {
                  const bgItem = bgColor.find((bg) => bg.id === item.id);
                  const itemBackgroundColor =
                    bookingDetails?.inspection_scheduled
                      ?.selected_slot_detail &&
                    employeeSlicedData &&
                    employeeSlicedData?.length > 0 &&
                    role === "user" &&
                    bgItem
                      ? bgItem.color
                      : role === "vendor" && bgItem
                      ? bgItem.color
                      : "white";
                  return (
                    <Box
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "8px",
                        alignItems: "center",
                        border: "2px solid #C1D0E0",
                        borderRadius: "18px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        minWidth: {xs: "200px", sm: "200px", md: "0"},
                        cursor:
                          !bookingDetails?.inspection_scheduled.hasOwnProperty(
                            "selected_slot_detail"
                          ) && role === "vendor"
                            ? "pointer"
                            : "",
                        backgroundColor: itemBackgroundColor,
                      }}
                      onClick={() => {
                        let body = {
                          time_slot: item.id,
                        };
                        if (
                          !bookingDetails?.inspection_scheduled.hasOwnProperty(
                            "selected_slot_detail"
                          ) &&
                          role === "vendor"
                        ) {
                          setAssignSlot(item);
                          saveTimeSlot(selectedService, body)
                            .then((res) => {
                              timeLineData(selectedService);
                            })
                            .catch((err) => {
                              errorNotification(
                                "Error in saving the slot .Please try again"
                              );
                            });
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        {dayjs(item.date).format("dddd")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#A7A7A7",
                          fontWeight: 400,
                        }}
                      >
                        {dayjs(item.date).format("DD MMM YYYY")}
                      </Typography>
                      <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                        {item.time_slot}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              columnGap: "10px",
              rowGap: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "10px",
              }}
            >
              {bookingDetails?.inspection_scheduled?.hasOwnProperty(
                "assigned_employees"
              ) && role === "user" ? (
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Employee assigned
                </Typography>
              ) : employeeSlicedData &&
                employeeSlicedData.length > 0 &&
                role === "vendor" ? (
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Employee assigned
                </Typography>
              ) : (
                ""
              )}

              {role === "vendor" &&
                bookingDetails &&
                Object.keys(bookingDetails?.inspection_scheduled).length ===
                  0 &&
                bookingDetails?.slots_provided.hasOwnProperty(
                  "assigned_employees"
                ) &&
                bookingDetails.slots_provided?.assigned_employees.length ===
                  0 &&
                !bookingDetails.complete.complete && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      float: "right",
                      color: "var(--secondary)",
                      cursor: "pointer",
                    }}
                    onClick={() => setAssignEmployeeModal(true)}
                  >
                    +Assign Employee
                  </Typography>
                )}
            </Box>

            <div
              style={{
                display: "flex",
                columnGap: 30,
              }}
            >
              {bookingDetails?.inspection_scheduled?.selected_slot_detail &&
              role === "user"
                ? employeeSlicedData &&
                  employeeSlicedData.map((item) => {
                    return (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {item?.profile_picture_url &&
                        item.profile_picture_url !== "" ? (
                          <Avatar
                            sx={{
                              width: 50,
                              height: 50,
                              marginBottom: 1,
                            }}
                            src={item.profile_picture_url}
                          ></Avatar>
                        ) : (
                          <Avatar
                            sx={{
                              bgcolor: "orange",
                              width: 50,
                              height: 50,
                              marginBottom: 1,
                              textTransform: "capitalize",
                            }}
                          >
                            {getInitials(
                              `${item.first_name} ${item.last_name}`
                            )}
                          </Avatar>
                        )}
                        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                          {item?.first_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#A7A7A7",
                            fontWeight: 400,
                          }}
                        >
                          {item?.emp_code} | {item?.designation}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#A7A7A7",
                            fontWeight: 400,
                          }}
                        >
                          {item?.phone_number}
                        </Typography>
                      </Box>
                    );
                  })
                : role === "vendor"
                ? bookingDetails?.slots_provided.hasOwnProperty(
                    "assigned_employees"
                  ) &&
                  bookingDetails?.slots_provided.assigned_employees?.length >
                    0 &&
                  employeeSlicedData &&
                  employeeSlicedData.map((item) => {
                    return (
                      <Box sx={{ display: "flex" }}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "10px",
                            alignItems: "center",
                          }}
                        >
                          {Object.keys(bookingDetails?.inspection_scheduled)
                            .length === 0 && (
                            <Button
                              variant="text"
                              sx={{
                                textAlign: "start",
                                width: 10,
                                marginLeft: "70px",
                                marginTop: "8px",
                              }}
                              onClick={() => {
                                let id =
                                  bookingDetails?.slots_provided.assigned_employees.map(
                                    (item) => item.id
                                  );
                                deleteEmployee(selectedService, id[0])
                                  .then((res) => {
                                    successNotification(
                                      "Successfully deleted the employee"
                                    );
                                    timeLineData(selectedService);
                                  })
                                  .catch((err) => {
                                    errorNotification(err.response.data.detail);
                                  });
                              }}
                            >
                              X
                            </Button>
                          )}
                          {item.profile_picture_url ?
                          <Avatar
                            sx={{
                              width: 50,
                              height: 50,
                              marginBottom: 1,
                            }}
                            src={item.profile_picture_url}
                          >
                          </Avatar> :
                          <Avatar>
                            {getInitials(
                              `${item.first_name} ${item.last_name}`
                            )}
                        </Avatar>
                          }
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 400 }}
                          >
                            {item?.first_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#A7A7A7",
                              fontWeight: 400,
                            }}
                          >
                            {item?.emp_code} | {item?.designation}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#A7A7A7",
                              fontWeight: 400,
                            }}
                          >
                            {item?.phone_number}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })
                : ""}
            </div>

            {bookingDetails &&
              role === "vendor" &&
              employeeSlicedData &&
              employeeSlicedData?.length > 0 &&
              Object.keys(bookingDetails?.inspection_scheduled)?.length === 0 &&
              !bookingDetails.complete.complete && (
                <Button
                  variant="contained"
                  sx={{
                    mt: 8,
                    borderRadius: 9999,
                    fontSize: 14,
                    height: "45px",
                    width: 300,
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (employeeSlicedData && employeeSlicedData?.length > 0)
                      confirmTimeAndEmployee(selectedService)
                        .then(() => {
                          successNotification(
                            "Slot and employee are scheduled."
                          );
                          timeLineData(selectedService);
                        })
                        .catch((err) => {
                          errorNotification(err.response.data.message);
                        });
                  }}
                >
                  Confirm Slot and assign the empolyee
                </Button>
              )}
          </Box>
        </Box>
      </Box>
    ),
  };
  return selectSlotByVendor;
};
