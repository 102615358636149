import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ open, handleClose, handleConfirm }) => {
  const theme = useTheme();
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', md: 400 }, // Responsive width
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: 4, // Added rounded corners
          textAlignLast: "center"
        }}
      >
        <WarningAmberIcon style={{ backgroundColor: "#fadbe0fc", borderRadius: "19px", fontSize: "38px", padding: "7px", color: "#ff0000b3"}} />
        <Typography variant="h6" gutterBottom style={{ marginTop: "15px"}}>
          Are you sure?
        </Typography>
        <Typography variant="body1" style={{ margin: "15px 0", textAlign: "center"}}>
          
          You want to delete your profile. Please confirm!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: theme.spacing(2),
          }}
        >
          <Button
            onClick={handleConfirm}
            variant="outlined"
            sx={{
              mr: 2,
            }}
          >
            Delete
          </Button>
          <Button onClick={handleClose} variant="contained" sx={{
              backgroundColor: 'var(--primary)',
              '&:hover': {
                backgroundColor: 'var(--hover)',
            },
          }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
