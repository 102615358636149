import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction } from "react";
import { BookingDetailTimelineResponse } from "../../types";
import ImageSliderModal from "../../components/shared/image-slider";
import { StepperType } from "../../types/StepperType";
import ReadMore from "../../components/shared/readmore-readless";

type Props = {
  bookingDetails: BookingDetailTimelineResponse;
  isImageSliderModalOpen: boolean;
  setIsImageSliderModalOpen: any;
  startIndex: number;
  setStartIndex: Dispatch<SetStateAction<number>>;
  imagePreviewFlag: string;
  setImagePreviewFlag: (image: string) => void;
  rejectWithReason: boolean;
  insecptionDetails: string;
  setInsecptionDetails: (details: string) => void;
};
export const InitialStepperRequest = ({
  bookingDetails,
  isImageSliderModalOpen,
  setIsImageSliderModalOpen,
  startIndex,
  setStartIndex,
  imagePreviewFlag,
  setImagePreviewFlag,
  rejectWithReason,
  insecptionDetails,
  setInsecptionDetails,
}: Props) => {
  let initialRequest: StepperType = {
    label: (
      <Box key={bookingDetails.customer_request.label}>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "var(--secondary)",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {bookingDetails ? bookingDetails.customer_request.label : ""}
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: 400, color: "#1F261F" }}
          >
            {bookingDetails &&
              dayjs(bookingDetails.customer_request.created_at).format(
                "DD MMM YYYY"
              )}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "#A7A7A7", fontWeight: 400 }}
          >
            {bookingDetails &&
              dayjs(bookingDetails.customer_request.created_at).format(
                "hh:mm A"
              )}
          </Typography>
        </Box>
      </Box>
    ),
    text: "Request",
    roles: ["user", "vendor"],
    status: bookingDetails ? bookingDetails.customer_request.status : "",
    description: (
      <Box sx={{ dispaly: "flex" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
              xl: 'row'
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              columnGap: "50px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: "10px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 1,
                }}
              >
                {bookingDetails && (
                  <Typography
                    sx={{ fontSize: 14 }}
                  >{`Number of services requested : ${bookingDetails?.customer_request.services?.length}`}</Typography>
                )}
                {bookingDetails
                  ? bookingDetails.customer_request.services.map(
                      (item: any, index: number) => {
                        return (
                          <Box>
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.primary"
                              gutterBottom
                            >
                              {`${index + 1}. ${item.name}`}
                            </Typography>
                          </Box>
                        );
                      }
                    )
                  : ""}
                {bookingDetails && (
                  <Typography
                    sx={{ fontSize: "14px", marginRight: "100px" }}
                    color="text.primary"
                    gutterBottom
                  >
                    <ReadMore>{` Note: ${bookingDetails.customer_request.notes}`}</ReadMore>
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                {bookingDetails
                  ? `${bookingDetails.customer_request.address.city} ${bookingDetails.customer_request.address.state} ${bookingDetails.customer_request.address.country}-${bookingDetails.customer_request.address.pincode}`
                  : ""}
              </Typography>
            </Box>
          </Box>
          <Box>
            {bookingDetails?.customer_request &&
              bookingDetails?.customer_request?.constomer_request_images
                ?.length > 0 && (
                <React.Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "10px",
                      alignItems: "flex-start"
                      // float: "right",
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                      Photos
                    </Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 2fr)",
                      }}
                    >
                      {bookingDetails?.customer_request
                        ?.constomer_request_images &&
                        bookingDetails?.customer_request?.constomer_request_images
                          .slice(0, 3)
                          .map((file: any, index: number) => {
                            return (
                              <React.Fragment key={file.image_id}>
                                <img
                                  style={{
                                    width: "65px",
                                    height: "65px",
                                    margin: "5px",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                  }}
                                  src={file.image_url}
                                  alt=""
                                  onClick={() => {
                                    setIsImageSliderModalOpen(true);
                                    setStartIndex(index);
                                    setImagePreviewFlag("view");
                                  }}
                                />
                                {bookingDetails?.customer_request
                                  ?.constomer_request_images.length > 3 &&
                                  index === 2 && (
                                    <Box
                                      sx={{
                                        position: "relative",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <label
                                        style={{
                                          width: "65px",
                                          height: "65px",
                                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                                          borderRadius: "8px",
                                          textAlign: "center",
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          color: "white",
                                          cursor: "pointer",
                                          margin: "5px",
                                        }}
                                        onClick={() => {
                                          setIsImageSliderModalOpen(true);
                                          setStartIndex(3);
                                          setImagePreviewFlag("view");
                                        }}
                                      >
                                        +{" "}
                                        {bookingDetails?.customer_request
                                          ?.constomer_request_images.length - 3}
                                      </label>
                                    </Box>
                                  )}
                              </React.Fragment>
                            );
                          })}
                    </Box>
                  </Box>

                  <ImageSliderModal
                    isImageSliderModalOpen={isImageSliderModalOpen}
                    onImageSliderModalClose={setIsImageSliderModalOpen}
                    images={
                      isImageSliderModalOpen
                        ? bookingDetails?.customer_request
                            ?.constomer_request_images
                        : []
                    }
                    startIndex={startIndex}
                    setStartIndex={setStartIndex}
                    imagePreviewFlag={imagePreviewFlag}
                    flag="object"
                  />
                </React.Fragment>
              )}
          </Box>
        </Box>
        {rejectWithReason && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
              mt: 5,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontSize: "14px",
                color: "var(--secondary)",
                fontWeight: 600,
              }}
            >
              Reject with Reason
            </Typography>
            <Typography>Please type in your comments</Typography>
            <textarea
              style={{
                border: "1px solid var(--primary)",
                borderRadius: 2,
                height: 100,
                padding: 4,
                resize: "none",
              }}
              onChange={(e) => {
                setInsecptionDetails(e.target.value);
              }}
              maxLength={5000}
              placeholder="Description must be 5000 characters or less."
            >
              {insecptionDetails}
            </textarea>
            <div style={{ display: "-webkit-flex" }}>
              <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                {insecptionDetails &&
                  `${5000 - insecptionDetails?.length} characters remaining`}
              </Typography>
            </div>
          </Box>
        )}
      </Box>
    ),
  };
  return initialRequest;
};
