import * as React from "react";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../utils/theme";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import { useNavigate, useLocation } from "react-router-dom";
import MainListItems from "../../components/core/sideBar/menu-list";
import { AppBar } from "../../components/core/header";

interface DashboardType {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  sidebarOpen?: boolean;
}

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px;
`;

export const Logo =
  // require("../../../src/assets/Logo-hello-fixy-green.svg").default;
  require("../../../src/assets/Logo-hello-fixy-blue2.svg").default;

export default function Dashboard(props: DashboardType) {
  const navigate = useNavigate();
  const location = useLocation();
  const { children } = props;
  const laptop = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);

  const medium = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (laptop) setOpen(true);
    else setOpen(false);
  }, [theme.breakpoints]);

  return (
    <Box sx={{ display: "flex"}}>
      {true && (
        <Box sx={{ display: "flex", width: "100%" }}>
          <Drawer
            sx={{
              width: 250,
              "& .MuiDrawer-paper": {
                width: 250,
                boxSizing: "border-box",
                backgroundColor: theme.palette.primary.light,
                borderRight: "none",
                whiteSpace: "nowrap",
                margin: 0,
                padding: 0,
                 // Custom scrollbar styles for the Drawer
                 '::-webkit-scrollbar': {
                  width: '8px',
                },
                '::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '10px',
                },
                'scrollbar-width': 'thin', // For Firefox
                'scrollbar-color': '#888 transparent',
              },
            }}
            variant={laptop ? "permanent" : "temporary"}
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 6,
                    paddingRight: 6,
                    paddingTop: 2,
                  }}
                >
                  <LogoContainer>
                    <img
                      src={Logo}
                      alt="Company Logo"
                      style={{
                        marginRight: 8,
                        height: 40,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleDrawerClose();
                        navigate("/?reset");
                      }}
                    />
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: "20px",
                        height: "30px",
                      }}
                    >
                      HelloFixy
                    </Typography>
                  </LogoContainer>
                </Typography>
              </Toolbar>

              <List
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <MainListItems open={open} handleDrawerClose={handleDrawerClose}/>
              </List>
            </Box>
          </Drawer>
          <Box sx={{ px: 3, mb: 0, width: "100%" }}>
            <AppBar
              navigate={navigate}
              location={location}
              open={open}
              setOpen={setOpen}
              medium={medium}
              active={medium}
            />
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
