import { SetStateAction, Dispatch } from "react";
import { Button, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { sendQuotation, startWork } from "../../action/timeline";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";
import {
  BookingDetailTimelineResponse,
  EmployeeDetails,
  GetQuotationResponse,
  selectedSlotsColor,
  selectedTimeSlots,
  timeSlot,
} from "../../types";
import { InitialStepperRequest } from "./InitalRequest";
import { StepperType } from "../../types/StepperType";
import RejectProject from "./RejectProject";
import { RequestBooking } from "./Request";
import { ScheduledInspection } from "./ScheduledInspection";
import { SelectSlotByVendor } from "./SelectSlotByVendor";
import { InsepectionDetailsByVendor } from "./InsepectionDetailsByVendor";
import { InsepectionDetails } from "./InsepectionDetails";
import SelectWorkSlotByVendor from "./SelectWorkSlotByVendor";
import workCompleteStep from "./workComplete";

export const PrimaryButton = styled(Button)`
  text-align: left;
  color: var(--btn-text);
  background: var(--primary);
  font-size: 16px;
  font: Kufam;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 20px;
  &:hover {
    background: var(--hover);
  }
  &:active {
    background: var(--primary);
  }
`
type Props = {
  bookingDetails: BookingDetailTimelineResponse;
  timeSlotSlicedData: false | selectedTimeSlots[] | null;
  role: string | null;
  setSlot: (slot: boolean) => void;
  bgColor: selectedSlotsColor;
  setAssignSlot: (assign: timeSlot) => void;
  employeeSlicedData: false | EmployeeDetails[] | null;
  setAssignEmployeeModal: (assignEmployee: boolean) => void;
  insecptionDetails: string;
  setInsecptionDetails: (details: string) => void;
  selectedService: string;
  timeLineData: Function;
  getInitials: (fullName: string) => string;
  setQuotation: (quotation: boolean) => void;
  setEditQuotation: (quotation: boolean) => void;
  setViewQuotation: (quotation: boolean) => void;
  quotationDetailsData: false | GetQuotationResponse[] | null;
  employeeWorkSlicedData: false | any[] | null;
  bgWorkColor: selectedSlotsColor;
  setAssignWorkEmployee: (employee: boolean) => void;
  setAssignWorkSlot: (assign: timeSlot) => void;
  setWorkSlot: (slot: boolean) => void;
  selectedFileDisplay: any;
  isImageUpdated: any;
  fileInputRef: any;
  handleFileChange: any;
  rejectWithReason: boolean;
  isImageSliderModalOpen: boolean;
  setIsImageSliderModalOpen: any;
  startIndex: number;
  setStartIndex: Dispatch<SetStateAction<number>>;
  deleteImage: () => void;
  imagePreviewFlag: string;
  setImagePreviewFlag: any;
  selectedFile: any;
  setSelectedFileDisplay: any;
  setSelectedFile: any;
  setIsImageUpdated: any;
  imgInspectionSlider: boolean;
  setImgInspectionSlider: Dispatch<SetStateAction<boolean>>;
  imgCompleteSlider: boolean;
  setImgCompleteSlider: Dispatch<SetStateAction<boolean>>;
};
export const Steps = ({
  bookingDetails,
  timeSlotSlicedData,
  role,
  setSlot,
  bgColor,
  setAssignSlot,
  employeeSlicedData,
  setAssignEmployeeModal,
  insecptionDetails,
  setInsecptionDetails,
  selectedService,
  timeLineData,
  getInitials,
  setQuotation,
  setEditQuotation,
  setViewQuotation,
  quotationDetailsData,
  employeeWorkSlicedData,
  bgWorkColor,
  setAssignWorkEmployee,
  setAssignWorkSlot,
  setWorkSlot,
  isImageUpdated,
  fileInputRef,
  selectedFileDisplay,
  handleFileChange,
  rejectWithReason,
  isImageSliderModalOpen,
  setIsImageSliderModalOpen,
  startIndex,
  setStartIndex,
  deleteImage,
  imagePreviewFlag,
  setImagePreviewFlag,
  selectedFile,
  setSelectedFileDisplay,
  setSelectedFile,
  setIsImageUpdated,
  imgInspectionSlider,
  setImgInspectionSlider,
  imgCompleteSlider,
  setImgCompleteSlider,
}: Props) => {
  let initialRequest = InitialStepperRequest({
    bookingDetails,
    isImageSliderModalOpen,
    setIsImageSliderModalOpen,
    startIndex,
    setStartIndex,
    imagePreviewFlag,
    setImagePreviewFlag,
    rejectWithReason,
    insecptionDetails,
    setInsecptionDetails,
  });

  let rejectProject = RejectProject({ bookingDetails });

  let request = RequestBooking({ bookingDetails });

  let scheduleInspection = ScheduledInspection({
    bookingDetails,
    timeSlotSlicedData,
    role,
    setSlot,
  });

  let selectSlotByVendor = SelectSlotByVendor({
    bookingDetails,
    role,
    timeSlotSlicedData,
    bgColor,
    setAssignSlot,
    timeLineData,
    selectedService,
    employeeSlicedData,
    setAssignEmployeeModal,
    getInitials,
  });

  let insepectionDetailsByVendor = InsepectionDetailsByVendor({
    setInsecptionDetails,
    insecptionDetails,
    selectedFile,
    setSelectedFile,
    selectedFileDisplay,
    setSelectedFileDisplay,
    imgInspectionSlider,
    setImgInspectionSlider,
    startIndex,
    setStartIndex,
    imagePreviewFlag,
    setImagePreviewFlag,
    isImageUpdated,
    setIsImageUpdated,
    timeLineData,
    selectedService,
    deleteImage,
    handleFileChange,
    fileInputRef,
  });

  let insepectionDetails = InsepectionDetails({
    bookingDetails,
    imgInspectionSlider,
    setImgInspectionSlider,
    imagePreviewFlag,
    setImagePreviewFlag,
    startIndex,
    setStartIndex,
  });

  let prepareQuotes: StepperType = {
    label: (
      <Button
        variant="outlined"
        onClick={() => setQuotation(true)}
        sx={{ cursor: "pointer" }}
      >
        Prepare Quotation
      </Button>
    ),
    roles: ["vendor"],
    description: <></>,
  };

  let getQuotes: StepperType = {
    label: "Prepare Quotation",
    roles: ["vendor"],
    description: (
      <Box
        sx={{
          display: "flex",
          columnGap: 3,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            !bookingDetails.complete.complete
              ? setEditQuotation(true)
              : setViewQuotation(true);
          }}
          sx={{ cursor: "pointer" }}
        >
          View Quotation
        </Button>
        {!bookingDetails?.quotation_ready.hasOwnProperty("label") &&
          !bookingDetails.complete.complete && (
            <Button
              variant="outlined"
              onClick={() => {
                sendQuotation(selectedService)
                  .then((res) => {
                    successNotification("Quotation send successfully");
                    timeLineData(selectedService);
                  })
                  .catch(() => {
                    errorNotification(
                      "Error in submiting quotaion.Please try again"
                    );
                  });
              }}
              sx={{ cursor: "pointer" }}
            >
              Send Quotation
            </Button>
          )}
      </Box>
    ),
  };

  let accpetQuotationByUser: StepperType = {
    text: role === "user" ? "Quotation Received" : "",
    label: (
      <Box
        key={`${
          bookingDetails && role === "user"
            ? bookingDetails?.quotation_ready.label
            : bookingDetails && role === "vendor"
            ? "Quotation Sent"
            : ""
        }`}
      >
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "var(--secondary)",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {`${
            bookingDetails && role === "user"
              ? bookingDetails?.quotation_ready.label
              : bookingDetails && role === "vendor"
              ? "Quotation Sent"
              : ""
          }`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
          }}
        >
          <Typography
            component="div"
            sx={{ fontSize: "12px", fontWeight: 400, color: "#1F261F" }}
          >
            {bookingDetails &&
              dayjs(bookingDetails?.quotation_ready.created_at).format(
                "DD MMM YYYY"
              )}
          </Typography>
          <Typography
            component="div"
            sx={{ fontSize: "12px", color: "#A7A7A7", fontWeight: 400 }}
          >
            {bookingDetails &&
              dayjs(bookingDetails?.quotation_ready.created_at).format(
                "hh:mm A"
              )}
          </Typography>
        </Box>
      </Box>
    ),
    roles: ["user", "vendor"],
    description: (
      <Box>
        {role === "vendor" &&
          bookingDetails &&
          !bookingDetails.complete.complete &&
          Object.keys(bookingDetails?.quotation_accepted)?.length === 0 && (
            <Button
              onClick={() => setEditQuotation(true)}
              sx={{ cursor: "pointer" }}
            >
              Edit Quotation
            </Button>
          )}
        {}

        {bookingDetails && bookingDetails.complete.complete ? (
          <Button onClick={() => setViewQuotation(true)}>View Quotation</Button>
        ) : (
          bookingDetails &&
          Object.keys(bookingDetails?.quotation_accepted)?.length > 0 && (
            <Button onClick={() => setViewQuotation(true)}>
              View Quotation
            </Button>
          )
        )}

        <Box
          sx={{
            fontSize: 14,
            ml: 1,
          }}
        >
          {quotationDetailsData &&
            quotationDetailsData?.map((item) => {
              return <div>{`Total: ₹${item.total + item.taxes}`}</div>;
            })}
        </Box>
      </Box>
    ),
  };

  let quotationAccepted: StepperType = {
    label: (
      <Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "var(--secondary)",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {`${bookingDetails ? bookingDetails?.quotation_accepted.label : ""}`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
          }}
        >
          <Typography
            component="div"
            sx={{ fontSize: "12px", fontWeight: 400, color: "#1F261F" }}
          >
            {bookingDetails &&
              dayjs(bookingDetails?.quotation_accepted.created_at).format(
                "DD MMM YYYY"
              )}
          </Typography>
          <Typography
            component="div"
            sx={{ fontSize: "12px", color: "#A7A7A7", fontWeight: 400 }}
          >
            {bookingDetails &&
              dayjs(bookingDetails?.quotation_accepted.created_at).format(
                "hh:mm A"
              )}
          </Typography>
        </Box>
      </Box>
    ),
    roles: ["vendor", "user"],
    description: <></>,
  };

  let scheduleWork: StepperType = {
    label: `Schedule Start of work`,
    roles: ["user"],
    status: "",
    description: (
      <Box>
        <Typography>Please choose three time slot</Typography>
        <Button
          variant="contained"
          sx={{ fontSize: 12, height: 30, marginTop: 2 }}
          onClick={() => {
            setWorkSlot(true);
            setSlot(true);
          }}
        >
          Choose time slot
        </Button>
      </Box>
    ),
  };

  let selectWorkSlotByVendor = SelectWorkSlotByVendor({
    bookingDetails,
    role,
    bgWorkColor,
    setAssignWorkSlot,
    selectedService,
    timeLineData,
    setAssignEmployeeModal,
    setAssignWorkEmployee,
    employeeWorkSlicedData,
    getInitials,
  });

  let workStart: StepperType = {
    label: (
      <Button
        variant="outlined"
        onClick={() =>
          startWork(selectedService)
            .then((res) => {
              successNotification("Work started successfully");
              timeLineData(selectedService);
            })
            .catch((err) => {
              errorNotification(err.response.data.detail);
            })
        }
        sx={{ cursor: "pointer" }}
      >
        Start Work
      </Button>
    ),
    roles: ["vendor"],
    description: <></>,
  };

  let workProgress: StepperType = {
    label: (
      <Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "var(--secondary)",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {`${bookingDetails ? bookingDetails?.in_progress.label : ""}`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: 400, color: "#1F261F" }}
          >
            {bookingDetails &&
              dayjs(bookingDetails?.in_progress.created_at).format(
                "DD MMM YYYY"
              )}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "#A7A7A7", fontWeight: 400 }}
          >
            {bookingDetails &&
              dayjs(bookingDetails?.in_progress.created_at).format("hh:mm A")}
          </Typography>
        </Box>
      </Box>
    ),
    roles: ["user", "vendor"],
    description: <div></div>,
  };

  let workComplete = workCompleteStep({
    bookingDetails,
    imgCompleteSlider,
    setImgCompleteSlider,
    startIndex,
    setStartIndex,
    imagePreviewFlag,
    setImagePreviewFlag,
  });

  if (bookingDetails) {
    if (
      bookingDetails.complete.complete &&
      bookingDetails.in_progress.in_progress
    ) {
      return [
        workComplete,
        workProgress,
        selectWorkSlotByVendor,
        quotationAccepted,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      bookingDetails.complete.complete &&
      bookingDetails.scheduled.schedule_confirmed
    ) {
      return [
        workComplete,
        selectWorkSlotByVendor,
        quotationAccepted,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      bookingDetails.complete.complete &&
      Object.keys(bookingDetails?.scheduled).length > 0
    ) {
      return [
        workComplete,
        selectWorkSlotByVendor,
        quotationAccepted,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      bookingDetails.complete.complete &&
      Object.keys(bookingDetails?.quotation_accepted).length > 0
    ) {
      return [
        workComplete,
        quotationAccepted,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      bookingDetails.complete.complete &&
      Object.keys(bookingDetails?.quotation_ready).length > 2
    ) {
      return [
        workComplete,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      bookingDetails.complete.complete &&
      Object.keys(bookingDetails?.quotation_ready).length > 0 &&
      bookingDetails?.quotation_ready.hasOwnProperty("quotation_prepared") &&
      bookingDetails?.quotation_ready.quotation_prepared === true &&
      quotationDetailsData &&
      quotationDetailsData.length > 0
    ) {
      return [
        workComplete,
        getQuotes,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      bookingDetails.complete.complete &&
      Object.keys(bookingDetails?.inspection_complete).length > 0 &&
      bookingDetails?.inspection_complete?.hasOwnProperty("inspection_details")
    ) {
      return [
        workComplete,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      bookingDetails.complete.complete &&
      Object.keys(bookingDetails.slots_provided).length > 0 &&
      bookingDetails.slots_provided.slots_detail.length > 0
    ) {
      return [workComplete, selectSlotByVendor, request, initialRequest];
    } else if (
      Object.keys(bookingDetails.customer_request).length > 0 &&
      Object.keys(bookingDetails.request_acceptance).length > 0 &&
      bookingDetails.complete.complete
    ) {
      return [workComplete, request, initialRequest];
    }
    if (bookingDetails.complete.complete) {
      return [
        workComplete,
        workProgress,
        selectWorkSlotByVendor,
        quotationAccepted,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (bookingDetails.in_progress.in_progress) {
      return [
        workProgress,
        selectWorkSlotByVendor,
        quotationAccepted,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (bookingDetails.scheduled.schedule_confirmed) {
      return [
        workStart,
        selectWorkSlotByVendor,
        quotationAccepted,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (Object.keys(bookingDetails?.scheduled).length > 0) {
      return [
        selectWorkSlotByVendor,
        quotationAccepted,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (Object.keys(bookingDetails?.quotation_accepted).length > 0) {
      return [
        scheduleWork,
        quotationAccepted,
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (Object.keys(bookingDetails?.quotation_ready).length > 2) {
      return [
        accpetQuotationByUser,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      Object.keys(bookingDetails?.quotation_ready).length > 0 &&
      bookingDetails?.quotation_ready.hasOwnProperty("quotation_prepared") &&
      bookingDetails?.quotation_ready.quotation_prepared === true &&
      quotationDetailsData &&
      quotationDetailsData.length > 0
    ) {
      return [
        getQuotes,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      Object.keys(bookingDetails?.inspection_complete).length > 0 &&
      bookingDetails?.inspection_complete?.hasOwnProperty("inspection_details")
    ) {
      return [
        prepareQuotes,
        insepectionDetails,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      Object.keys(bookingDetails?.inspection_scheduled).length > 0 &&
      bookingDetails?.inspection_scheduled?.selected_slot_detail[0]
    ) {
      return [
        insepectionDetailsByVendor,
        selectSlotByVendor,
        request,
        initialRequest,
      ];
    } else if (
      Object.keys(bookingDetails.slots_provided).length > 0 &&
      bookingDetails.slots_provided.slots_detail.length > 0
    ) {
      return [selectSlotByVendor, request, initialRequest];
    } else if (
      Object.keys(bookingDetails.customer_request).length > 0 &&
      Object.keys(bookingDetails.request_acceptance).length > 0
    ) {
      return [scheduleInspection, request, initialRequest];
    } else if (bookingDetails.rejected.rejected) {
      return [rejectProject, initialRequest];
    } else if (Object.keys(bookingDetails?.customer_request).length > 0) {
      return [initialRequest];
    } else return [initialRequest];
  } else return [initialRequest];
};
