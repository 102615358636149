import {
  Card,
  Grid,
  Typography,
  TextField,
  Box,
  InputLabel,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  ListItem,
  Divider,
  Paper,
  useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";

import React, { useEffect, useMemo, useRef, useState } from "react";
import AddressModal from "./address";
import { useSelector } from "react-redux";
import { RootState, setBookingStoreDetails } from "../../store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { theme } from "../../utils/theme";
import { PhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import axios from "axios";
import PreviewIcon from "@mui/icons-material/Preview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ImageSliderModal from "../../components/shared/image-slider";
import ReadMore from "../../components/shared/readmore-readless";

interface Service {
  id: string;
  name: string;
  short_description: string;
  description: string;
  image_url: string;
}
interface Address {
  id: string;
  title: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  address_type: string;
}

const Addservice = (props: any) => {
  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const accessToken = localStorage.getItem("access_token");
  const {
    bookingDetails,
    setBookingDetails,
    setSelectedService,
    setAddService,
    setEditService,
    previewCart,
    setPreviewCart,
    setSelectedServiceId,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state: RootState) => state.userDetail); // Profile value from Store

  const [isImageSliderModalOpen, setIsImageSliderModalOpen] =
    useState<boolean>(false);
  const [imagePreviewFlag, setImagePreviewFlag] = useState<string>("");
  const [contactDetailsType, setContactDetailsType] = useState<null | string>(
    (bookingDetails && bookingDetails.contact_details_type) ||
    (userDetail?.username && userDetail?.username.startsWith("+91")
      ? "self"
      : "others")
  );

  const [contactName, setContactName] = useState<null | string>(
    userDetail ? `${userDetail.first_name} ${userDetail.last_name}` : null
  );

  const [contactNumber, setContactNumber] = useState<null | string>(
    userDetail ? `${userDetail.country_code}${userDetail.phone_number}` : null
  );
  const [otherContactName, setOtherContactName] = useState<null | string>(
    bookingDetails &&
      bookingDetails.contact_details_type &&
      bookingDetails.contact_details_type === "others"
      ? bookingDetails.contact_name
      : ""
  );
  const [otherContactNumber, setOtherContactNumber] = useState<null | string>(
    bookingDetails &&
      bookingDetails.contact_details_type &&
      bookingDetails.contact_details_type === "others"
      ? bookingDetails.contact_number
      : ""
  );

  const [notes, setNotes] = useState<null | string>(
    bookingDetails && bookingDetails.notes ? bookingDetails.notes : null
  );

  const [primaryAddress, setPrimaryAddress] = useState<any | null>(
    userDetail?.addresses.find(
      (address: Address) => address.address_type === "PRIMARY"
    )
  );

  const [selectedAddress, setSelectedAddress] = useState<any | null>(
    bookingDetails && bookingDetails.address_details
      ? bookingDetails.address_details
      : null
  );

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactDetailsType(event.target.value);
  };

  const handleOpenAddressModal = () => {
    setIsAddressModalOpen(true);
  };

  const nameInputRef = useRef<HTMLInputElement>(null);
  const numberInputRef = useRef<HTMLInputElement>(null);
  const notesInputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   if (nameInputRef.current) {
  //     nameInputRef.current.focus();
  //   }
  // }, [contactName]);

  // useEffect(() => {
  //   if (numberInputRef.current) {
  //     numberInputRef.current.focus();
  //   }
  // }, [contactNumber]);

  // useEffect(() => {
  //   if (notesInputRef.current) {
  //     notesInputRef.current.focus();
  //   }
  // }, [notes]);

  const TextDisabled = useMemo(() => {
    if (notes && notes.length > 0 && 200 - notes.length === 0) {
      return true;
    } else return false;
  }, [notes]);

  const getCartDetails = async () => {
    try {
      let url: string = `${apiEndpoint}/bookings/12345/get_booking_cart`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();

      if (data) {
        dispatch(setBookingStoreDetails(data));
      } else {
        throw new Error("Error in booking service");
      }
    } catch (error) {
      console.error("Error fetching Services:", error);
    }
  };

  const deleteService = async (service: any) => {
    let url: string = `${apiEndpoint}/bookings/`;

    const response = await fetch(url, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        service_id: service.id,
      }),
    });

    const data = await response.json();
    if (data) {
      const prevBookingdetails = JSON.stringify(bookingDetails);
      const prevBookingdetailsParsed = JSON.parse(prevBookingdetails);
      const updatedBookingdetails =
        prevBookingdetailsParsed.service_list.filter(
          (bookings: any) => bookings.id !== service.id
        );

      prevBookingdetailsParsed.service_list = updatedBookingdetails;

      if (updatedBookingdetails.length === 0) {
        setBookingDetails(null);
        dispatch(setBookingStoreDetails(null));
        setSelectedServiceId("");
      } else {
        setBookingDetails(prevBookingdetailsParsed);
        dispatch(setBookingStoreDetails(prevBookingdetailsParsed));
      }
      await getCartDetails();
      toast.success(`${service.name} service removed from cart`, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      toast.error(`Unable to remove ${service.name}`, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const validateMobileNumber = (mobileNumber: string): boolean => {
    // const mobileNumberRegex = /^[0-9]{10}$/;
    const mobileNumberRegex = /^(\+91[\-\s]?)?[6789]\d{9}$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  const saveBookingDetails = async (flag: string) => {
    if (bookingDetails) {
      const name =
        contactDetailsType === "self" ? contactName : otherContactName;
      const phone =
        contactDetailsType === "self" ? contactNumber : otherContactNumber;

      if (!name) {
        toast.error(`Contact name is required`, {
          position: "top-right",
          autoClose: 3000,
        });
        return;
      }
      if (!phone) {
        toast.error(`Phone number is required`, {
          position: "top-right",
          autoClose: 3000,
        });
        return;
      }

      if (!phone.startsWith("+91")) {
        toast.error(`Please provide an Indian phone number exclusively for contact information.`, {
          position: "top-right",
          autoClose: 3000,
        });
        return;
      }

      if (!validateMobileNumber(phone)) {
        toast.error(`Invalid phone number`, {
          position: "top-right",
          autoClose: 3000,
        });
        return;
      }

      let url: string = `${apiEndpoint}/bookings/${bookingDetails.id}/save_booking/`;
      const requestBody: any = {
        contact_details_type: contactDetailsType,
        contact_name: name,
        contact_number: phone,
        notes: notes,
      };

      if (isImageUpdated) handleUpload(); // If image uploaded flag set true

      if (selectedAddress?.id || primaryAddress?.id) {
        requestBody.address = selectedAddress?.id || primaryAddress?.id;
      }
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();

      if (data) {
        if (flag === "draft") {
          toast.success(`Draft booking details saved`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      }
    }
  };

  const saveBooking = async () => {
    let url: string = `${apiEndpoint}/bookings/${bookingDetails.id}/submit_booking/`;

    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();

    if (data && data.succes) {
      toast.success(`Booking submited successfully. Redirecting to dashboard`, {
        position: "top-right",
        autoClose: 1500,
      });

      setTimeout(() => {
        navigate("/customer-dashboard");
      }, 1500);
    } else {
      toast.warning(`Unable to submit booking`, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const ViewDetailsLink = styled(Button)`
    text-align: left;
    padding:"4px 0 0 0"
    align-items: flex-start;
    text-transform: capitalize;
  `;

  const SubmitButton = styled(Button)`
    color: #ffffff;
    background: var(--primary);
    font: Kufam;
    border-radius: 30px;

    &:hover {
      background: var(--hover); /* Change the background color on hover */
    }

    &:active {
      background: var(--primary); /* Change the background color on click */
    }
  `

  const [selectedFile, setSelectedFile] = useState<string[]>([]);
  const [selectedFileDisplay, setSelectedFileDisplay] = useState<any[]>([]);
  const [isImageUpdated, setIsImageUpdated] = useState<boolean>(false);
  const [startIndex, setStartIndex] = useState(0);
  const fileInputRef = React.useRef(null);

  const handleAddMoreService = () => {
    setSelectedService(null);
    setEditService(false);
    setAddService(false);
  };

  useEffect(() => {
    if (bookingDetails) {
      if (
        bookingDetails.booking_images &&
        bookingDetails.booking_images.length > 0
      ) {
        const imageList = bookingDetails.booking_images.map((image: any) => {
          return image.image_url;
        });
        // setSelectedFile(imageList);
        setSelectedFileDisplay(imageList);
      }
    }
  }, []);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    setIsImageUpdated(true);

    if (file) {
      setSelectedFile([file, ...selectedFile]);

      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result;
        setSelectedFileDisplay([dataUrl, ...selectedFileDisplay]);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    try {
      if (selectedFile.length > 0) {
        const formData = new FormData();

        const filteredArray = selectedFile.filter(
          (item) => typeof item === "object"
        );
        for (let i = 0; i < filteredArray.length; i++) {
          formData.append("images", filteredArray[i]);
        }
        const randomNo = Math.floor(Math.random() * 900000) + 100000;
        formData.append("job_progress_details", randomNo.toString());
        formData.append("detail_type", "request");

        const response = await axios.put(
          `${apiEndpoint}/bookings/${bookingDetails.id}/upload_booking_images/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response && response.data) {
          setSelectedFile([]);
          setIsImageUpdated(false);
        }
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const isURL = (value: string): boolean => {
    // Regular expression to match a URL pattern
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Test the input string against the URL regex
    return urlRegex.test(value);
  };

  const deleteImage = async () => {
    const selectedImage = selectedFileDisplay[startIndex];
    const isInputURL = isURL(selectedImage);
    if (isInputURL) {
      const matchingObject = bookingDetails.booking_images.find(
        (obj: any) => obj.image_url === selectedImage
      );

      let url: string = `${apiEndpoint}/bookings/${bookingDetails.id}/delete_booking_image?image_id=${matchingObject.id}`;
      const response = await fetch(url, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const updatedImages = bookingDetails.booking_images.filter(
          (obj: any) => obj.image_url !== selectedImage
        );
        setBookingDetails({
          ...bookingDetails,
          booking_images: updatedImages,
        });
        const imageList = updatedImages.map((image: any) => {
          return image.image_url;
        });
        setSelectedFileDisplay(imageList);
      }
    } else {
      const newArray = selectedFileDisplay.filter(
        (value) => value !== selectedImage
      );      
      setSelectedFileDisplay(newArray);
      const selectedImageFile = selectedFile[startIndex];
      const newArrayUpload = selectedFile.filter(
        (value) => value !== selectedImageFile
      );
      setSelectedFile(newArrayUpload);
      setStartIndex(0);
    }
    if (selectedFileDisplay.length === 0) {
      setIsImageSliderModalOpen(false);
    }
  };

  // useEffect(() => {
  //   if (!isImageSliderModalOpen) {
  //     setSelectedFileDisplay([]);
  //   }
  // }, [isImageSliderModalOpen]);
  const isTabletScreen = useMediaQuery('(max-width: 768px)');

  return (
    <Box sx={{ width: "100%" }}>
      {!previewCart && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={9} sx={{ height: "inherit" }}>
            <Card sx={{ padding: "1rem 1.5rem", position: "relative" }}>
              <Grid container sx={{ mb: 2 }}>
                <Grid xs={6} sx={{ display: "flex", alignItems: "center"}}>
                  <Typography
                    sx={{
                      fontSize: "0.85rem",
                      fontWeight: 500,
                      lineHeight: "1rem",
                      color: "#36423C",
                    }}
                  >
                    Services selected
                    {bookingDetails &&
                      bookingDetails.service_list &&
                      bookingDetails.service_list.length > 0 && (
                        <span> ({bookingDetails.service_list.length})</span>
                      )}
                  </Typography>
                </Grid>
                <Grid xs={6} sx={{ textAlign: "right" }}>
                  <ViewDetailsLink onClick={handleAddMoreService} sx={{fontSize: "0.85rem"}}>
                    Add more service
                  </ViewDetailsLink>
                </Grid>
              </Grid>
              <Box
                sx={{
                  py: "0.5rem"
                  // maxHeight: "350px",
                  // overflowY: "scroll",
                  // height: "450px",
                }}
              >
                {bookingDetails?.service_list &&
                  bookingDetails.service_list.length > 0 &&
                  bookingDetails.service_list.map((service: Service) => (
                    <Card
                      key={service.id}
                      sx={{
                        mt: 2,
                        mb: 2,
                        ml: 1,
                        mr: 1,
                        width: "95%",
                        // border: '1px solid',
                        // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px !important',
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          padding: "0.5rem",
                        }}
                      >
                        <Box sx={{ display: {xs: 'none', sm: 'block'}}}>
                        <img
                          src={service.image_url}
                          alt={service.name}
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "8px",
                          }}
                        />
                        </Box>
                        <Box sx={{ width: "100%",  marginLeft: "0.5rem", }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#36423C",
                                fontSize: "16px",
                                fontWeight: 700,
                                lineHeight: "30px",
                              }}
                            >
                              {service.name}
                            </Typography>
                            <DeleteIcon
                              sx={{
                                color: "#9BA09E",
                                mr: 2,
                                ml: 2,
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                deleteService(service);
                              }}
                            />
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#A7A7A7",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              maxHeight: "50",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              mr: 2,
                              mt: 1,
                            }}
                          >
                            {service.short_description}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  ))}
              </Box>
            </Card>
            <Card
              sx={{
                padding: "20px 30px",
                mt: -3,
                position: "relative",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
              }}
            >
              <Grid container sx={{ mb: 1, bottom: 0 }}>
              {isTabletScreen ? (
                <>
                  <Grid item xs={12} sx={{ px: 2, mb: 1 }}> 
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        color: "#36423C",
                      }}
                    >
                      Add details <span>*</span>
                    </Typography>

                    <textarea
                      id="notes"
                      style={{
                        border: "1px solid var(--primary)",
                        borderRadius: 2,
                        height: 100,
                        padding: 6,
                        width: "100%",
                        resize: "none",
                        font: "Kufam",
                      }}
                      onChange={(event) => setNotes(event.target.value)}
                      maxLength={5000}
                      placeholder="Description must be 5000 characters or less."
                    >
                      {notes}
                    </textarea>
                    <div style={{ display: "-webkit-flex" }}>
                      <Typography sx={{ fontWeight: 500 }}>
                        {notes && `${5000 - notes.length} characters remaining`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2} sx={{ px: 2 }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        color: "#36423C",
                      }}
                    >
                      Photos
                    </Typography>

                    <Box
                      sx={{
                        display: "grid",
                        columnGap: 1,
                        rowGap: 1,
                        gridTemplateColumns: "repeat(2, 2fr)",
                      }}
                    >
                      {selectedFileDisplay &&
                        selectedFileDisplay.length > 0 &&
                        selectedFileDisplay
                          .slice(0, 3)
                          .map((file: any, index: number) => {
                            return (
                              <React.Fragment>
                                <img
                                  style={{
                                    width: "65px",
                                    height: "65px",
                                    margin: "5px",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                  }}
                                  // src={URL.createObjectURL(file)}
                                  src={file}
                                  alt="Uploaded photo"
                                  onClick={() => {
                                    setIsImageSliderModalOpen(true);
                                    setStartIndex(index);
                                    setImagePreviewFlag("edit");
                                  }}
                                />
                                {selectedFileDisplay.length > 3 &&
                                  index === 2 && (
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "133px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <label
                                        style={{
                                          width: "65px",
                                          height: "65px",
                                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                                          borderRadius: "8px",
                                          textAlign: "center",
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          color: "white",
                                          cursor: "pointer",
                                          margin: "5px",
                                        }}
                                        onClick={() => {
                                          setIsImageSliderModalOpen(true);
                                          setStartIndex(2);
                                          setImagePreviewFlag("edit");
                                        }}
                                      >
                                        + {selectedFileDisplay.length - 3}
                                      </label>
                                    </Box>
                                  )}
                              </React.Fragment>
                            );
                          })}

                      <label
                        htmlFor="fileInput"
                        style={{
                          width: "65px",
                          height: "65px",
                          backgroundColor: "#1F261F",
                          borderRadius: "8px",
                          textAlign: "center",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          color: "white",
                          cursor: "pointer",
                          margin: "5px",
                        }}
                      >
                        + Add
                      </label>
                      <input
                        id="fileInput"
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={(event) => handleFileChange(event)}
                        accept=".png, .jpg, .jpeg"
                      />
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                <Grid item xs={9}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "30px",
                      color: "#36423C",
                    }}
                  >
                    Add details <span>*</span>
                  </Typography>

                  <textarea
                    id="notes"
                    style={{
                      border: "1px solid var(--primary)",
                      borderRadius: 2,
                      height: 100,
                      padding: 6,
                      width: "100%",
                      resize: "none",
                      font: "Kufam",
                    }}
                    onChange={(event) => setNotes(event.target.value)}
                    maxLength={5000}
                    placeholder="Description must be 5000 characters or less."
                  >
                    {notes}
                  </textarea>
                  <div style={{ display: "-webkit-flex" }}>
                    <Typography sx={{ fontWeight: 500 }}>
                      {notes && `${5000 - notes.length} characters remaining`}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2} sx={{ px: 2 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "30px",
                      color: "#36423C",
                    }}
                  >
                    Photos
                  </Typography>

                  <Box
                    sx={{
                      display: "grid",
                      columnGap: 1,
                      rowGap: 1,
                      gridTemplateColumns: "repeat(2, 2fr)",
                    }}
                  >
                    {selectedFileDisplay &&
                      selectedFileDisplay.length > 0 &&
                      selectedFileDisplay
                        .slice(0, 3)
                        .map((file: any, index: number) => {
                          return (
                            <React.Fragment>
                              <img
                                style={{
                                  width: "65px",
                                  height: "65px",
                                  margin: "5px",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                                // src={URL.createObjectURL(file)}
                                src={file}
                                alt="Uploaded photo"
                                onClick={() => {
                                  setIsImageSliderModalOpen(true);
                                  setStartIndex(index);
                                  setImagePreviewFlag("edit");
                                }}
                              />
                              {selectedFileDisplay.length > 3 &&
                                index === 2 && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: "133px",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    <label
                                      style={{
                                        width: "65px",
                                        height: "65px",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        borderRadius: "8px",
                                        textAlign: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "white",
                                        cursor: "pointer",
                                        margin: "5px",
                                      }}
                                      onClick={() => {
                                        setIsImageSliderModalOpen(true);
                                        setStartIndex(2);
                                        setImagePreviewFlag("edit");
                                      }}
                                    >
                                      + {selectedFileDisplay.length - 3}
                                    </label>
                                  </Box>
                                )}
                            </React.Fragment>
                          );
                        })}

                    <label
                      htmlFor="fileInput"
                      style={{
                        width: "65px",
                        height: "65px",
                        backgroundColor: "#1F261F",
                        borderRadius: "8px",
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        color: "white",
                        cursor: "pointer",
                        margin: "5px",
                      }}
                    >
                      + Add
                    </label>
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={(event) => handleFileChange(event)}
                      accept=".png, .jpg, .jpeg"
                    />
                  </Box>
                </Grid>
                </>
                )}
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4} xl={3} sx={{ height: "inherit" }}>
            <Card sx={{ m: 0 }}>
              <div style={{ padding: "16px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "30px",
                    color: "#36423C",
                    mb: 2,
                  }}
                >
                  Contact Details
                </Typography>
                <FormControl sx={{ mb: 2 }}>
                  <RadioGroup
                    row
                    aria-labelledby="service-is-for"
                    name="position"
                    defaultValue={contactDetailsType}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="self"
                      control={<Radio />}
                      label="Self"
                    />
                    <FormControlLabel
                      value="others"
                      control={<Radio />}
                      label="Others"
                    />
                  </RadioGroup>
                </FormControl>
                <InputLabel htmlFor="contact_name">
                  Name <span>*</span>
                </InputLabel>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <TextField
                    id="contact_name"
                    name="contact_name"
                    fullWidth
                    margin="normal"
                    sx={{ width: "100%" }}
                    value={
                      contactDetailsType === "self" ? contactName : otherContactName
                    }
                    onChange={(event) => {
                      const nameRegex = /^[a-zA-Z\s]*$/; // allows letters and spaces
                      const newValue = event.target.value;
                      if (nameRegex.test(newValue)) {
                        contactDetailsType === "self"
                          ? setContactName(newValue)
                          : setOtherContactName(newValue);
                      }
                    }}
                    inputRef={nameInputRef}
                    disabled={contactDetailsType === "self"}
                  />
                </Box>
                <InputLabel htmlFor="mobile-no">
                  Contact number <span>*</span>
                </InputLabel>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <TextField
                    type="tel"
                    id="contact_number"
                    name="contact_number"
                    fullWidth
                    margin="normal"
                    sx={{ width: "100%" }}
                    value={
                      contactDetailsType === "self"
                        ? contactNumber || ""  
                        : otherContactNumber || "" 
                    }
                    onChange={(event) => {
                      let inputValue = event.target.value;
                    
                      if (inputValue.length <= 3 && !inputValue.startsWith("+91")) {
                        inputValue = "+91";
                      } else {
                        
                        inputValue = inputValue.startsWith("+91")
                          ? inputValue
                          : `+91${inputValue.replace(/\D/g, "")}`;
                      }
                    
                      if (inputValue.length > 3) {
                        const digitsAfterPrefix = inputValue.slice(3).replace(/\D/g, "");
                        inputValue = `+91${digitsAfterPrefix.slice(0, 10)}`;
                      }
                    
                      if (contactDetailsType === "self") {
                        setContactNumber(inputValue);
                      } else {
                        setOtherContactNumber(inputValue);
                      }
                    }}
                    onFocus={() => {
                      if (contactDetailsType !== "self" && !(otherContactNumber || "").startsWith("+91")) {
                        setOtherContactNumber("+91");
                      } else if (contactDetailsType === "self" && !(contactNumber || "").startsWith("+91")) {
                        setContactNumber("+91");
                      }
                    }}
                    inputRef={numberInputRef}
                    disabled={contactDetailsType === "self"}
                    placeholder="Enter phone number"
                  />
                </Box>
                <InputLabel htmlFor="address"> Address</InputLabel>
                {(selectedAddress || primaryAddress) && (
                  <Box sx={{ my: 2, wordWrap: "break-word" }}>
                    {selectedAddress
                      ? `${selectedAddress.address}, ${selectedAddress.city}, ${selectedAddress.state}, ${selectedAddress.country}, ${selectedAddress.pincode}`
                      : `${primaryAddress.address}, ${primaryAddress.city}, ${primaryAddress.state}, ${primaryAddress.country}, ${primaryAddress.pincode}`}
                  </Box>
                )}
                <Button
                  variant="outlined"
                  type="button"
                  onClick={handleOpenAddressModal}
                  sx={{
                    borderRadius: "30px",
                    mt: 1,
                    mb: 5,
                    minWidth: "125px"
                  }}
                >
                  {!selectedAddress ? "Add address" : "Change Address"}
                </Button>
                {/* <InputLabel htmlFor="notes"> Notes</InputLabel>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  id="notes"
                  multiline
                  fullWidth
                  rows={4}
                  margin="normal"
                  sx={{ width: '100%' }}
                  value={notes}
                  onChange={(event) => setNotes(event.target.value)}
                  inputRef={notesInputRef}
                />
              </Box> */}
              </div>
            </Card>
            {bookingDetails &&
              bookingDetails.service_list &&
              bookingDetails.service_list.length > 0 && (
                <Box
                  sx={{
                    textAlign: "right",
                    mt: 2,
                    mb: 4,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      saveBookingDetails("draft");
                      // navigate('/dashboard')
                    }}
                    sx={{ borderRadius: "30px", minWidth: "125px", margin: "0 0.25rem" }}
                  >
                    Save as draft
                  </Button>
                  <SubmitButton
                    type="button"
                    sx={{
                      borderRadius: "30px",
                      minWidth: "125px",
                      margin: "0 0.25rem"
                    }}
                    onClick={() => {
                      const name =
                        contactDetailsType === "self" ? contactName : otherContactName;
                      const phone =
                        contactDetailsType === "self" ? contactNumber : otherContactNumber;
                      if (
                        (selectedAddress || primaryAddress) &&
                        name &&
                        phone &&
                        notes
                      ) {
                        if (!phone.startsWith("+91")) {
                          toast.error(`Please provide an Indian phone number exclusively for contact information.`, {
                            position: "top-right",
                            autoClose: 3000,
                          });
                          return;
                        }
                  
                        if (!validateMobileNumber(phone)) {
                          toast.warning(`Invalid phone number`, {
                            position: "top-right",
                            autoClose: 3000,
                          });
                          return;
                        }
                        saveBookingDetails("save");
                        setPreviewCart(true);
                      }

                      if (!selectedAddress && !primaryAddress) {
                        toast.error(`Select an address to proceed`, {
                          position: "top-right",
                          autoClose: 5000,
                        });
                      }
                      if (!name) {
                        toast.error(`Enter contact name`, {
                          position: "top-right",
                          autoClose: 5000,
                        });
                      }
                      if (!phone) {
                        toast.error(`Enter contact number`, {
                          position: "top-right",
                          autoClose: 5000,
                        });
                      }
                      if (!notes) {
                        toast.error(`Add elaborated details`, {
                          position: "top-right",
                          autoClose: 5000,
                        });
                      }
                    }}
                  >
                    Proceed
                  </SubmitButton>
                </Box>
              )}
          </Grid>
        </Grid>
      )}
      {previewCart && (
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid item xs={12} sx={{ height: "inherit" }}>
            <Paper sx={{ width: "100%" }}>
              <Grid container sx={{ padding: "20px 30px" }}>
                <Grid xs={12}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "30px",
                      color: "#36423C",
                      mb: 1.5,
                    }}
                  >
                    Total services
                    {bookingDetails &&
                      bookingDetails.service_list &&
                      bookingDetails.service_list.length > 0 && (
                        <span> ({bookingDetails.service_list.length})</span>
                      )}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  {bookingDetails?.service_list &&
                    bookingDetails.service_list.length > 0 &&
                    bookingDetails.service_list.map(
                      (service: Service, index: number) => {
                        return (
                          <Grid xs={12}>
                            <ListItem key={index} sx={{ pl: 0 }}>
                              {index + 1}. {service.name}
                            </ListItem>
                          </Grid>
                        );
                      }
                    )}
                </Grid>
              </Grid>
              <Divider />
              <Grid container sx={{ padding: "20px 30px" }}>
                <Grid xs={12} sm={10}>
                  <Grid xs={12}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "30px",
                        color: "#36423C",
                        mb: 1.5,
                      }}
                    >
                      Request details
                    </Typography>
                  </Grid>
                  <Grid xs={12} sx={{ width: "100%", fontSize: "14px" }}>
                    {notes && <ReadMore>{notes}</ReadMore>}
                  </Grid>
                </Grid>
                <Grid xs={2}>
                  {selectedFileDisplay.length > 0 && (
                    <React.Fragment>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 500,
                          lineHeight: "30px",
                          color: "#36423C",
                        }}
                      >
                        Photos
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          columnGap: 1,
                          rowGap: 1,
                          gridTemplateColumns: "repeat(2, 2fr)",
                        }}
                      >
                        {selectedFileDisplay &&
                          selectedFileDisplay.length > 0 &&
                          selectedFileDisplay
                            .slice(0, 3)
                            .map((file: any, index: number) => {
                              return (
                                <React.Fragment>
                                  <img
                                    style={{
                                      width: "65px",
                                      height: "65px",
                                      margin: "5px",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                    src={file}
                                    alt=""
                                    onClick={() => {
                                      setIsImageSliderModalOpen(true);
                                      setStartIndex(index);
                                      setImagePreviewFlag("view");
                                    }}
                                  />
                                  {selectedFileDisplay.length > 3 &&
                                    index === 2 && (
                                      <Box
                                        sx={{
                                          position: "relative",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        <label
                                          style={{
                                            width: "65px",
                                            height: "65px",
                                            backgroundColor:
                                              "rgba(0, 0, 0, 0.5)",
                                            borderRadius: "8px",
                                            textAlign: "center",
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            color: "white",
                                            cursor: "pointer",
                                            margin: "5px",
                                          }}
                                          onClick={() => {
                                            setIsImageSliderModalOpen(true);
                                            setStartIndex(3);
                                            setImagePreviewFlag("view");
                                          }}
                                        >
                                          + {selectedFileDisplay.length - 3}
                                        </label>
                                      </Box>
                                    )}
                                </React.Fragment>
                              );
                            })}
                      </Box>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Grid container sx={{ padding: "20px 30px", display: isTabletScreen ? "block" : "flex"}}>
                <Grid xs={3} sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "30px",
                      color: "#36423C",
                      mb: 1.5,
                    }}
                  >
                    Contact details
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "30px",
                      color: "#36423C",
                    }}
                  >
                    {contactDetailsType === "self"
                      ? contactName
                      : otherContactName}
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "16px",
                      lineHeight: "30px",
                      color: "#36423C",
                    }}
                  >
                    {contactDetailsType === "self"
                      ? contactNumber
                      : otherContactNumber}
                  </Typography>
                </Grid>
                <Grid xs={6} sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "30px",
                      color: "#36423C",
                      mb: 1.5,
                    }}
                  >
                    Address
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "16px",
                      lineHeight: "30px",
                      color: "#36423C",
                      wordWrap: "break-word",
                    }}
                  >
                    {`${selectedAddress?.address || primaryAddress?.address}, ${
                      selectedAddress?.city || primaryAddress?.city
                    }, ${selectedAddress?.state || primaryAddress?.state}, ${
                      selectedAddress?.country || primaryAddress?.country
                    }, ${selectedAddress?.pincode || primaryAddress?.pincode}`}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              <Grid sx={{ padding: "20px 30px", textAlign: "center" }}>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    setPreviewCart(false);
                  }}
                  sx={{ borderRadius: "30px", minWidth: '200px', my: '0.5rem', mx: '0.25rem' }}
                >
                  Edit
                </Button>
                <SubmitButton
                  sx={{ borderRadius: "30px", minWidth: '200px', my: '0.5rem', mx: '0.25rem' }}
                  type="button"
                  onClick={saveBooking}
                >
                  Submit request
                </SubmitButton>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
      <AddressModal
        open={isAddressModalOpen}
        setIsAddressModalOpen={setIsAddressModalOpen}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      />
      <ImageSliderModal
        isImageSliderModalOpen={isImageSliderModalOpen}
        onImageSliderModalClose={setIsImageSliderModalOpen}
        images={selectedFileDisplay}
        startIndex={startIndex}
        setStartIndex={setStartIndex}
        deleteImage={deleteImage}
        imagePreviewFlag={imagePreviewFlag}
        flag="string"
      />
    </Box>
  );
};

export default Addservice;
