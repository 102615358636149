import React, { useState, useRef } from "react"
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import { Box, Button } from "@mui/material"

const MapWithLocation = (props: any) => {
  const { location, setLocation } = props

  const [selectedMarker, setSelectedMarker] = useState<any | null>(null)
  const mapRef = useRef<google.maps.Map | null>(null)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY!,
    libraries: ["places", "geocoding"],
  })

  if (loadError) return <div>Error loading maps</div>
  if (!isLoaded) return <div>Loading...</div>

  const setMarker = (latitude: number, longitude: number) => {
    setLocation({ lat: latitude, lng: longitude })
    mapRef.current?.panTo({ lat: latitude, lng: longitude })
    createMarker({ lat: latitude, lng: longitude })
  }

  const fetchGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          setMarker(latitude, longitude)
        },
        (error) => {
          console.error("Error getting geolocation:", error)
        },
        {
          enableHighAccuracy: true,
        }
      )
    } else {
      alert("Geolocation not supported in this browser.")
    }
  }

  const fetchGeoCode = async () => {
    let address = { ...props.address }
    address = [address.street, address.city].join(", ")
    const geocoder = new window.google.maps.Geocoder()
    return new Promise((resolve) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK" && results && results.length > 0) {
          const { lat, lng } = results[0].geometry.location
          setMarker(lat(), lng())
        } else {
          alert("Could not locate your address on map. Please try again")
        }
        resolve(true)
      })
    })
  }

  const createMarker = (position: google.maps.LatLngLiteral) => {
    const marker = new window.google.maps.Marker({
      position,
      map: mapRef.current,
      draggable: true,
      title: "Selected Location",
    })

    setSelectedMarker(marker)

    // Update state with the selected location
    setLocation({ lat: position.lat, lng: position.lng })

    // { lat: 13.067439, lng: 80.237617 } Chennai
    // Listen for marker dragend event
    window.google.maps.event.addListener(marker, "dragend", () => {
      const newPosition = marker.getPosition()
      setLocation({
        lat: newPosition?.lat() || 13.067439,
        lng: newPosition?.lng() || 80.237617,
      })
    })
  }

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event && event?.latLng) {
      createMarker(event?.latLng.toJSON())
    }
  }
  //   useEffect(() => {
  //     if (flag === 'edit') {
  //       const marker = new window.google.maps.Marker({
  //         position: location.toJSON(),
  //         map: mapRef.current,
  //         draggable: true,
  //         title: 'Selected Location',
  //       });

  //       setSelectedMarker(marker);
  //     }
  //   }, []);

  return (
    <Box sx={{ ml: { md: "20px" } }}>
      <Button
        variant='outlined'
        onClick={fetchGeoLocation}
        sx={{ my: 1, mx: 0.5 }}>
        Get my current location
      </Button>
      <Button variant='outlined' onClick={fetchGeoCode} sx={{ my: 1, mx: 0.5 }}>
        Get location from address
      </Button>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={location || { lat: 0, lng: 0 }}
        zoom={15}
        onClick={handleMapClick}>
        {selectedMarker && selectedMarker?.position && (
          <Marker position={selectedMarker.position.toJSON()} />
        )}
      </GoogleMap>
      {location && (
        <div style={{ visibility: "hidden" }}>
          <h3>Selected Location</h3>
          <p>Latitude: {location.lat}</p>
          <p>Longitude: {location.lng}</p>
        </div>
      )}
    </Box>
  )
}

export default MapWithLocation
