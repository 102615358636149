import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SupportIcon from "@mui/icons-material/Support";
import PeopleIcon from "@mui/icons-material/People";
import { Link, useLocation } from "react-router-dom";
import {
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  Box,
  List,
  IconButton,
} from "@mui/material";
import { theme } from "../../../utils/theme";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState, setRole, setUserDetail } from "../../../store";
import { useEffect } from "react";
import { getInitials } from "../header";
import ContactsIcon from "@mui/icons-material/Contacts";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import ServicesIcon from '@mui/icons-material/DesignServices';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from "react-router-dom";
interface MenuItemType {
  id: number;
  route: string;
  label: string;
  icon: any;
}

const homeMenu: MenuItemType[] = [
  {
    id: 1,
    route: "/",
    label: "Home",
    icon: HomeIcon,
  },
  {
    id: 2,
    route: "/all-services",
    label: "Services",
    icon: ServicesIcon,
  },
  {
    id: 3,
    route: "/help-center",
    label: "FAQs",
    icon: SupportIcon,
  },
  {
    id: 4,
    route: "/about",
    label: "About Us",
    icon: PeopleIcon,
  },
  {
    id: 5,
    route: "/login",
    label: "Log In",
    icon: LoginIcon,
  }
];

const CustomerMenu: MenuItemType[] = [
  {
    id: 1,
    route: "/all-services",
    label: "Services",
    icon: FormatListBulletedIcon,
  },
  {
    id: 2,
    route: "/customer-dashboard",
    label: "My Bookings",
    icon: DashboardIcon,
  },
  {
    id: 2,
    route: "/help-center",
    label: "Help Center",
    icon: SupportIcon,
  },
];
const vendorMenu: MenuItemType[] = [
  {
    id: 1,
    route: "/projects",
    label: "Projects",
    icon: DashboardIcon,
  },
  {
    id: 4,
    route: "/employees",
    label: "Employees",
    icon: PeopleIcon,
  },
];

const MainListItems = ({
  handleDrawerClose
}: any) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(setRole(""));
    dispatch(setUserDetail(null));
    localStorage.clear();
    localStorage.clear();

  };
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const role = localStorage.getItem("role");

  const location = useLocation();

  const [menuList, setMenuList] = React.useState<any>(vendorMenu);

  const userDetail = useSelector((state: RootState) => state.userDetail);

  useEffect(() => {
    let menuListTemp: any = [];
    if (role === "vendor") {
      menuListTemp = vendorMenu;
    }

    if (role === "user") {
      menuListTemp = CustomerMenu;
    }
    setMenuList(menuListTemp);
  }, [role]);

  const footerDataCustomer: MenuItemType[] = [
    {
      id: 1,
      route: "/profile",
      label: "Profile",
      icon: PeopleIcon,
    },
    {
      id: 2,
      route: "/address",
      label: "Manage Addresses",
      icon: ContactsIcon,
    },
    {
      id: 3,
      route: "/",
      label: "Logout",
      icon: LogoutIcon,
    },
  ];

  const footerDataVendor: MenuItemType[] = [
    {
      id: 3,
      route: "/vendor-login",
      label: "Logout",
      icon: LogoutIcon,
    },
  ];

  const footerData = role === "vendor" ? footerDataVendor : footerDataCustomer;

  return (
    <>
      {isAuthenticated === "true" && userDetail && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            {menuList.length > 0 &&
              menuList.map((item: any, index: number) => (
                <Link key={index} to={item.route + `${item.label === 'Dashboard' || item.label === 'Projects' ? '?reset' : '' }`}>
                  <ListItemButton
                    sx={{
                      m: "24px 0px",
                      borderLeft: `4px solid ${
                        location.pathname === item.route
                          ? theme.palette.primary.main
                          : "transparent"
                      }`,
                      backgroundColor:
                        location.pathname === item.route ? "#fff" : "inherits",
                    }}
                    onClick={() => item.label === "Logout" && handleLogout() || handleDrawerClose()}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "35px",
                      }}
                    >
                      <item.icon
                        color={
                          location.pathname === item.route
                            ? "primary"
                            : "inherit"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ marginLeft: "24px" }}
                      primary={
                        <Typography
                          color={
                            location.pathname === item.route
                              ? "primary"
                              : "inherit"
                          }
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </Link>
              ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
              marginTop: { xs: "0px", sm: "60px", md: "90px", lg: "120px", xl: "150px" },
            }}
          >
            <Box>
              {footerData.length > 0 &&
                footerData.map((item: any, index: number) => (
                  <Link key={index} to={item.route}>
                    <ListItemButton
                      sx={{
                        m: "10px 0px",
                        borderLeft: `4px solid ${
                          location.pathname === item.route
                            ? theme.palette.primary.main
                            : "transparent"
                        }`,
                        backgroundColor:
                          location.pathname === item.route
                            ? "#fff"
                            : "inherits",
                      }}
                      onClick={() => {
                        localStorage.removeItem("selectedService");
                        handleDrawerClose();
                        if (item.label === 'Logout') handleLogout();
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "35px",
                        }}
                      >
                        <item.icon
                          color={
                            location.pathname === item.route
                              ? "primary"
                              : "inherit"
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ marginLeft: "24px" }}
                        primary={
                          <Typography
                            color={
                              location.pathname === item.route
                                ? "primary"
                                : "inherit"
                            }
                          >
                            {item.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </Link>
                ))}
            </Box>
            <ListItem sx={{ textWrap: 'wrap'}}>
              <ListItemAvatar>
                {userDetail?.profile_picture_url &&
                userDetail.profile_picture_url !== "" ? (
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                      marginBottom: 1,
                      border: "1px solid #ccc",
                    }}
                    src={userDetail.profile_picture_url}
                  ></Avatar>
                ) : (
                  <Avatar>
                    {getInitials(
                      `${userDetail?.first_name} ${userDetail?.last_name}`
                    )}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                sx={{ fontSize: "18px" }}
                primary={`${userDetail?.first_name} ${userDetail?.last_name}`}
              />
            </ListItem>
          </Box>
        </Box>
      )}
    </>
  );
};

export const HomeMenu = () => {

  const location = useLocation();
  const navigate = useNavigate();
  console.log(homeMenu);

  return (
    <Box><>
    {
      homeMenu.map((item: any, index: number) => (
        <Link key={index} to={item.route}>
          <ListItemButton
            sx={{
              m: "24px 0px",
              borderLeft: `4px solid ${
                location.pathname === item.route
                  ? theme.palette.primary.main
                  : "transparent"
              }`,
              backgroundColor:
                location.pathname === item.route ? "#fff" : "inherits",
            }}
            onClick={() => navigate(item.route)}
          >
            <ListItemIcon
              sx={{
                minWidth: "35px",
              }}
            >
              <item.icon
                color={
                  location.pathname === item.route
                    ? "primary"
                    : "inherit"
                }
              />
            </ListItemIcon>
            <ListItemText
              sx={{ marginLeft: "24px" }}
              primary={
                <Typography
                  color={
                    location.pathname === item.route
                      ? "primary"
                      : "inherit"
                  }
                >
                  {item.label}
                </Typography>
              }
            />
          </ListItemButton>
        </Link>
      ))
    }
    </></Box>
  );

}

export default MainListItems;
